/**
 * @author fero
 */

export function formatPrice(numberString, currency='EUR', nullString='-') {
    return numberString != null ? (Number(numberString).toFixed(2) + ' ' + currency) :  nullString;
}

export function formatUnitPrice(unitPriceString, unitString, currency = 'EUR', nullString='-') {
    if(unitPriceString != null)
    {
        const unitPrice = Number(unitPriceString);
        const fracString = unitPrice.toString().split('.', 2)[1] || '';
        const decimals = Math.min(Math.max(fracString.length, 2), 4);
        return (unitPrice.toFixed(decimals) + ' ' + currency) + 
            (unitString != null ? ('/' + unitString) : '');
    }
    else
        return nullString;
}

export function formatProductName(designation, manufacturer) {
    return designation + (manufacturer != null ? ' (' + manufacturer + ')' : '');
}

export function formatQuantity(quantityString, unitString) {
    return (formatNumber(quantityString) + (unitString != null ? (' ' + unitString) : ''));
}

export function formatQuantityAvailable(quantityString, unitString) {
    return Number.isNaN(Number(quantityString)) ? 
        quantityString : 
        (Number(quantityString) + (unitString != null ? (' ' + unitString) : ''));
}

export function formatQuantityPerPackage(quantityPerPackage, quantityUnits, packageType) {
    return (quantityPerPackage != null ? "1 " + packageType + " = " + formatNumber(quantityPerPackage) + " " + quantityUnits : null);
}

export function formatCustomerCode(code) {
    return (code != null ? "(" + code + ")" : "");
}

export function formatPercentage(ratioString) {
    return ratioString != null ? (Number(ratioString)*100) + '%' : '-';
}

export function formatString(string, nullString) {
    return (string != null && string != '') ? string : (nullString != null ? nullString : '-');
}

export function formatNumber(numberString) {
    const num = numberString != null ? Number(numberString) : null;
    return num != null ? (Math.round(num * 1e6) / 1e6) : '-';
}

export function formatUserInfoName(userInfo, nullString='-') {
    if(userInfo == null || userInfo.surname == null)
        return nullString;
    else
        return formatNamePart(userInfo.title) + formatNamePart(userInfo.name) + userInfo.surname;
}

function formatNamePart(part) {
    return part ? (part + " ") : "";
}

export function formatApiMonth(year, month) {
    return String(year) + '-' + String(month).padStart(2, '0');
}

export function formatBoolean(value) {
    if (value) {
        return 'Áno';
    } else {
        return 'Nie';
    }
}

export function limitString(string, maxLength) {
    if(string != null && string.length > maxLength-3) {
        return (string.substr(0, maxLength-3) + '...');
    }
    else {
        return string;
    }
}