/**
 * @fero
 */

//cookie names
export const RIGHTS_COOKIE = 'rights';
export const TRUE_RIGHTS_COOKIE = 'true_rights';
export const CONSENT_COOKIE = 'CookieConsent';
export const IS_MOBILE_COOKIE = 'is_mobile';
export const OCI_HOOK_COOKIE = 'ociHook';

export const ROUTES = {
    HOME: '/',
    CONTRACT: '/contract/',
    PRODUCT_DETAILS: '/product/details/',
    CATEGORY_ADD: '/categoryAdd/',
    CATEGORY_EDIT: '/categoryEdit/',
    DISCOUNT_RULES: '/discountRules/',
    DASHBOARD: '/dashboard/',
    ASSIGNMENTS: '/assignments/',
    CART: '/cart/',
    ACCOUNT: '/account/',
    LOGIN: '/login/',
    COMMERCIAL_TERMS: '/commercialTerms/',
    CLAIM_RULES: '/claimRules/',
    PERSONAL_DATA_PROTECTION: '/personalDataProtection/',
    INVOICES: '/invoices/',
    ORDERS: '/orders/',
    NEW_ORDER: '/newOrder/',
    ORDER_DETAILS: '/orderDetails/',
    ORDER_LINES: '/orderLines/',
    QUOTATIONS: '/quotations/',
    QUOTATION_DETAILS: '/quotationDetails/',
    QUOTATION_MAKE_ORDER: '/quotationMakeOrder/',
    REQUESTS: '/requests/',
    REQUEST_DETAILS: '/requestDetails/',
    CUSTOMERS: '/customers/',
    CUSTOMER_DETAILS: '/customers/details/',
    CUSTOMERS_USER_DETAILS: '/customers/userDetails/',
    SHOPPING_LISTS: '/shoppingLists/',
    NEWS: '/news/',
    NEWS_DETAIL: '/news/detail/',
    EVENTS: '/events/',
    ADMINISTRATION_MANUFACTURERS: '/admin/',
    ADMINISTRATION_CUSTOMER_GROUPS: '/admin/customerGroups/',
    ADMINISTRATION_EVENT_TYPES: '/admin/eventTypes/',
    ADMINISTRATION_FLAGS: '/admin/flags/',
    ADMINISTRATION_VEHICLES: '/admin/vehicles/',
    ADMINISTRATION_IMPORT_EXPORT: '/admin/importExport/',
    ADMINISTRATION_DEPARTMENTS: '/admin/departments/',
    ADMINISTRATION_FORMS: '/admin/forms/',
    ADMINISTRATION_HISTORY: '/admin/history/',
    ADMINISTRATION_BACKUP: '/admin/backup/',
    ADMINISTRATION_INVENTORY_CLASSES: '/admin/inventoryClasses/',
    REPORTS: '/report/',
    REPORT_MANAGE: '/report/manage/',
    REPORT_DISPLAY: '/report/display/',
    ANALYSIS: '/analysis/',
    ALERTS: '/alerts/',
    PAGE_400: '/400/',
    PAGE_404: '/404/',
    PAGE_500: '/500/',
    ERROR: '/error/',
};

export const STOCK_ROUTES = {
    DISPATCH_DETAILS: '/dispatch/detail.php',
    RECEIVE_DETAILS: '/receive/detail.php',
    INVOICE_DETAILS: '/invoices/detail.php',
    COMPLAINT_DETAILS: '/complaints/detail.php',
    INVENTORY_DETAILS: '/items/detail.php',
    SHIPMENT_DETAILS: '/pack/detail.php',
    PACKAGE_DETAILS: '/pack/package.php'
};

export const PARENT_ROUTES = {
    REQUEST: '/request/',
    QUOTATION: '/quotation/',
    ORDER: '/order/',
    ADMINISTRATION: ROUTES.ADMINISTRATION_MANUFACTURERS,
    REPORT: ROUTES.REPORTS,
};

export const ERROR_ROUTES = [
    ROUTES.PAGE_400,
    ROUTES.PAGE_404,
    ROUTES.PAGE_500,
    ROUTES.ERROR,
    ROUTES.LOGIN,
];

export const QUERY_PARAMS = {
    // GENERAL
    RETURN_TO: 'returnTo',
    OCI_HOOK: 'HOOK_URL',
    API_KEY: 'api_key',
    DEBUG: 'debug',
    SHOW_API_KEY: 'show_api_key',
    LANG: 'lang',
    
    // PRODUCTS
    ID_MANUFACTURER: 'id_manufacturer',
    ID_REF_CUSTOMER: 'id_ref_customer',
    ID_CUSTOMER: 'id_customer',
    ID_PRODUCT_GROUP: 'id_product_group',
    ID_PRODUCT: 'id_product',
    ID_VARIANT: 'id_variant',
    DESIGNATION: 'designation',
    SEARCH_TYPE: 'search_type',
    PRODUCTS_SEARCH_OPTIONS: 'p_search_options',
    PRODUCTS_SHOW_FILTERS: 'p_show_flt',
    PRODUCTS_AVAILABILITY: 'p_avail',
    PRODUCTS_LIMIT: 'p_limit',
    PRODUCTS_OFFSET: 'p_offs',
    PRODUCTS_SCROLL: 'products_scroll',
    PRODUCTS_IS_VISIBLE: 'p_is_visible',
    PRODUCTS_HIDE_REF: 'p_hide_ref',
    PRODUCTS_SHOW_IMG: 'p_show_img',
    PRODUCTS_ORDER_BY: 'p_order_by',
    PRODUCTS_LARGE_CATEGORY: 'p_large_cat',
    SEARCH_CROSS_REFS: 'p_sh_cr',

    // CART
    ID_USER: 'id_user',
    ID_DELIVERY_ADDRESS: 'id_delivery_address',
    ID_INVOICE_ADDRESS: 'id_invoice_address',
    ID_TRANSPORT_TYPE: 'id_transport_type',
    ID_PAYMENT_METHOD: 'id_payment_method',
    DATE_REQUESTED: 'date_requested',
    CUSTOMER_REFERENCE: 'customer_reference',
    CUSTOMER_NOTICES: 'customer_notices',
    DEALER_NOTICES: 'dealer_notices',
    ORDER_FORM_TRADE: 'of_trade',
    ORDER_FORM_IS_DIVISIBLE: 'is_divisible',
    ORDER_FORM_ID_DST_STOCK: 'of_id_dst_stock',
    ORDER_FORM_PROXY: 'of_proxy',
    FINAL_ORDER_REFERENCE: 'fo_ref',
    FINAL_CUSTOMER_NAME: 'fo_customer',
    FINAL_USER_FULLNAME: 'fo_user_name',
    FINAL_USER_EMAIL: 'fo_user_email',
    FINAL_USER_PHONE: 'fo_user_phone',
    FINAL_USER_LANGUAGE: 'fo_user_lang',
    FINAL_ADDRESS_STREET: 'fo_addr_street',
    FINAL_ADDRESS_ZIP_CODE: 'fo_addr_zip',
    FINAL_ADDRESS_CITY: 'fo_addr_city',
    FINAL_ADDRESS_COUNTRY: 'fo_addr_country',

    CART_STEP: 'cart_step',
    CART_ORDER_BY: 'cart_order_by',
    CART_LIMIT: 'cart_limit',
    CART_OFFSET: 'cart_offs',
    CART_SCROLL: 'cart_scroll',

    // CONTRACT
    CONTRACT_DESIGNATION: 'co_designation',
    CONTRACT_SEARCH_TYPE: 'co_search_type',
    CONTRACT_ID_MANUFACTURER: 'co_id_manufacturer',
    CONTRACT_ID_REF_CUSTOMER: 'co_id_ref_customer',
    CONTRACT_AVAILABILITY: 'co_avail',
    CONTRACT_LIMIT: 'co_limit',
    CONTRACT_OFFSET: 'co_offs',
    CONTRACT_ORDER_BY: 'co_order',
    CONTRACT_SCROLL: 'co_scroll',

    // CUSTOMERS and USERS
    CUSTOMER_USER_PHRASE: 'customer_user_phrase',
    CUSTOMER_SHOW_HIDDEN: 'customer_show_hidden',
    CUSTOMER_USERS_LIMIT: 'cd_limit',
    CUSTOMER_USERS_ORDER_BY: 'cd_order',
    CUSTOMER_ID_SALESMAN: 'cu_salesman',
    CUSTOMER_ID_SALESMAN2: 'cu_salesman2',
    CUSTOMER_ID_TYPE: 'cu_type',
    CUSTOMER_ID_GROUP: 'cu_group',
    CUSTOMER_AUTO_INVOICE: 'cu_auto_inv',
    CUSTOMER_PAYMENT_DUE: 'cu_pay_due',
    USERS_NEWSLETTER: 'user_news',
    USERS_LIMIT: 'user_limit',
    USERS_ORDER_BY: 'user_order',
    USERS_SCROLL: 'users_scroll',
    ID_PAGE_CUSTOMER: 'id_page_customer',
    USER_DETAILS_ID_USER: 'ud_id_user',
    ADDRESSES_SHOW_HIDDEN: 'ua_show',

    // DISCOUNTS
    ID_DISCOUNT_GROUP: 'id_discount_group',
    DISCOUNT_RULES_PHRASE: 'dr_phrase',

    // DASHBOARD
    DASHBOARD_YEAR: 'dash_yr',
    DASHBOARD_MONTH: 'dash_mon',
    DASHBOARD_ID_USER: 'dash_user',

    // ASSIGNMENTS
    ASSIGNMENTS_YEAR: 'asgn_yr',
    ASSIGNMENTS_MONTH: 'asgn_mon',
    ASSIGNMENTS_ID_USER: 'asgn_user',

    // INVOICES
    ID_INVOICE: 'id_invoice',
    INVOICE_CLASS: 'inv_class',
    INVOICE_ID_CUSTOMER: 'inv_customer',
    INVOICE_ID_USER: 'inv_user',
    INVOICE_ID_SALESMAN: 'inv_salesman',
    INVOICE_AT: 'inv_at',
    INVOICE_PAYMENT_DUE: 'inv_pay_due',
    INVOICE_IS_OVERDUE: 'inv_overdue',
    INVOICE_IS_PAID: 'inv_paid',
    INVOICE_REF: 'inv_ref',
    INVOICE_TRANSFERED_AT: 'inv_transf_at',
    INVOICE_SENT_AT: 'inv_sent_at',
    INVOICE_ORDER_BY: 'inv_order',
    INVOICE_LIMIT: 'inv_limit',
    INVOICE_OFFSET: 'inv_offset',
    INVOICE_SCROLL: 'inv_scroll',

    // ORDERS
    ID_ORDER: 'id_order',
    ORDER_DETAIL_SHOW_PAYMENT_MESSAGE: 'show_msg',
    ORDER_DETAIL_ORDER_BY: 'od_order',
    ORDER_DETAIL_LIMIT: 'od_limit',
    ORDER_DETAIL_OFFSET: 'od_offset',
    ORDER_DETAIL_STATUS: 'od_status',
    ORDER_DETAIL_PHRASE: 'od_phrase',
    ORDER_TRADE: 'o_trade',
    ORDER_ID_DEALER: 'o_id_dealer',
    ORDER_ID_SALESMAN: 'o_id_salesman',
    ORDER_ID_SALESMAN2: 'o_id_salesman2',
    ORDER_REF: 'o_ref',
    ORDER_CUSTOMER_REFERENCE: 'o_customer_ref',
    ORDER_DEAL_CODE: 'o_deal',
    ORDER_ID_USER: 'o_id_user',
    ORDER_ID_STATUS: 'o_id_status',
    ORDER_ID_CUSTOMER: 'o_id_customer',
    ORDER_CREATED_AT: 'o_send_at',
    ORDER_LAST_CHANGE: 'o_last_change',
    ORDER_DATE_REQUESTED: 'o_date_requested',
    ORDER_NOTICES: 'o_notices',
    ORDER_DESIGNATION: 'o_designation',
    ORDER_DEPARTMENT: 'o_depart',
    ORDER_SHOW_REMOTE: 'o_remote',
    ORDER_LIMIT: 'o_limit',
    ORDER_ORDER_BY: 'o_order',
    ORDER_SCROLL: 'o_scroll',

    // ORDER LINES
    ORDER_LINES_ORDER_REFERENCE: 'ol_ref',
    ORDER_LINES_CUSTOMER_REFERENCE: 'ol_customer_ref',
    ORDER_LINES_DESIGNATION: 'ol_designation',
    ORDER_LINES_ID_STATUS: 'ol_id_status',
    ORDER_LINES_SENT_AT: 'ol_sent_at',
    ORDER_LINES_REQUESTED_AT: 'ol_req_at',
    ORDER_LINES_AVAILABLE_AT: 'ol_avail_at',
    ORDER_LINES_ID_CUSTOMER: 'ol_customer',
    ORDER_LINES_ID_MANUFACTURER: 'ol_manuf',
    ORDER_LINES_LIMIT: 'ol_limit',
    ORDER_LINES_ORDER_BY: 'ol_order',
    ORDER_LINES_SCROLL: 'ol_scroll',

    // REQUESTS
    ID_REQUEST: 'id_request',
    REQUEST_DETAIL_ORDER_BY: 'rd_order',
    REQUEST_DETAIL_LIMIT: 'rd_limit',
    REQUEST_DETAIL_OFFSET: 'rd_offset',
    REQUEST_DETAIL_PHRASE: 'rd_phrase',
    REQUEST_TRADE: 'r_trade',
    REQUEST_ID_CUSTOMER: 'r_id_customer',
    REQUEST_ID_DEALER: 'r_id_dealer',
    REQUEST_ID_SALESMAN: 'r_id_salesman',
    REQUEST_ID_SALESMAN2: 'r_id_salesman2',
    REQUEST_ID_USER: 'r_id_user',
    REQUEST_ID_STATUS: 'r_id_status',
    REQUEST_REF: 'r_ref',
    REQUEST_CUSTOMER_REF: 'r_customer_ref',
    REQUEST_CREATED_AT: 'r_created_at',
    REQUEST_NOTICES: 'r_notices',
    REQUEST_LIMIT: 'r_limit',
    REQUEST_ORDER_BY: 'r_order',
    REQUEST_SCROLL: 'r_scroll',
    
    // QUOTATIONS
    ID_QUOTATION: 'id_quotation',
    QUOTATION_ORDER_ID_CUSTOMER: 'qor_id_customer',
    QUOTATION_DETAIL_ORDER_BY: 'qd_order',
    QUOTATION_DETAIL_LIMIT: 'qd_limit',
    QUOTATION_DETAIL_OFFSET: 'qd_offset',
    QUOTATION_DETAIL_PHRASE: 'qd_phrase',
    QUOTATION_ORDER_ITEMS: 'items',
    QUOTATION_TRADE: 'q_trade',
    QUOTATION_ID_CUSTOMER: 'q_id_customer',
    QUOTATION_ID_DEALER: 'q_id_dealer',
    QUOTATION_ID_SALESMAN: 'q_id_salesman',
    QUOTATION_ID_SALESMAN2: 'q_id_salesman2',
    QUOTATION_ID_USER: 'q_id_user',
    QUOTATION_ID_STATUS: 'q_id_status',
    QUOTATION_REF: 'q_ref',
    QUOTATION_CUSTOMER_REF: 'q_customer_ref',
    QUOTATION_REQUEST_REF: 'q_request_ref',
    QUOTATION_NOTICES: 'q_notices',
    QUOTATION_SENT_AT: 'q_sent_at',
    QUOTATION_LAST_CHANGE: 'q_last_change',
    QUOTATION_ACTIVE_TO: 'q_active_to',
    QUOTATION_LIMIT: 'q_limit',
    QUOTATION_ORDER_BY: 'q_order',
    QUOTATION_SCROLL: 'q_scroll',
    
    // NEWS
    ID_NEWS: 'id_news',
    NEWS_PHRASE: 'n_phrase',
    NEWS_ID_PRODUCT_GROUP: 'n_id_product_group',
    NEWS_ID_AUTHOR: 'n_id_author',
    NEWS_ACTIVE_AT: 'n_active_at',
    NEWS_LAST_CHANGE: 'n_last_change',
    NEWS_SHOW_HIDDEN: 'n_show_hidden',
    NEWS_LIMIT: 'n_limit',

    // SHOPPING LIST
    ID_SHOPPING_LIST: 'id_shopping_list',
    SHOPPING_LIST_TOKEN: 'token',

    // REPORTS
    ID_REPORT: 'id_report',
    REPORT_NAME: 'rep_name',
    REPORT_ID_OWNER: 'rep_id_owner',
    REPORT_ACTION: 'rep_act',

    // FORMS
    ID_FORM: 'id_form',

    // HISTORY
    HISTORY_CLASS: 'h_class',
    HISTORY_METHOD: 'h_method',
    HISTORY_AT: 'h_at',
    HISTORY_WHO: 'h_who',
    HISTORY_LIMIT: 'h_limit',

    // ANALYSIS
    ANALYSIS_PARAMS: 'anal_params',
    ANALYSIS_PREFETCH: 'anal_prefetch',

    // EVENTS
    EVENT_ID_TYPE: 'ev_type',
    EVENT_START_AT: 'ev_start',
    EVENT_HAS_REFUEL: 'ev_refuel',
    EVENT_ID_VEHICLE: 'ev_vehicle',
    EVENT_ID_WORKER: 'ev_worker',
    EVENT_ID_USER: 'ev_user',
    EVENT_ID_CUSTOMER: 'ev_customer',
    EVENT_PHRASE: 'ev_phrase',
    EVENT_ORDER_BY: 'ev_order',
    EVENT_LIMIT: 'ev_limit',
    EVENT_SCROLL: 'ev_scroll',

    // ALERTS:
    ALERT_PHRASE: 'al_phrase',
    ALERT_ID_SALESMAN: 'al_salesman',
    ALERT_ID_SALESMAN2: 'al_salesman2',
    ALERT_CLASS: 'al_class',
    ALERT_SHOW_ALL: 'al_show_all',
    ALERT_SHOW_FOREIGN: 'al_show_foreign',
    ALERT_ID_TYPE: 'al_type',
    ALERT_TIM: 'al_tim',
    ALERT_ID_CUSTOMER: 'al_customer',
    ALERT_ORDER_BY: 'al_order',
    ALERT_LIMIT: 'al_limit',
    ALERT_SCROLL: 'al_scroll',

    // DEPARTMENTS:
    DEPARTMENT_SHOW_ALL: 'dep_show_all',

    // GOOGLE ANALYTICS
    REPORT_TO_GOOGLE: 'ga',
};

//enables to define defaults for routes that are set when navigating to route if query attributes are not defined when
//calling navigation
export const ROUTE_QUERY_DEFAULTS = {
    [ROUTES.HOME]: {
        [QUERY_PARAMS.PRODUCTS_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.CART]: {
        [QUERY_PARAMS.CART_LIMIT]: process.env.DEFAULT_CART_RESULT_LIMIT,
    },
    [ROUTES.CONTRACT]: {
        [QUERY_PARAMS.CONTRACT_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.REQUESTS]: {
        [QUERY_PARAMS.REQUEST_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.REQUEST_DETAILS]: {
        [QUERY_PARAMS.REQUEST_DETAIL_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.QUOTATIONS]: {
        [QUERY_PARAMS.QUOTATION_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.QUOTATION_DETAILS]: {
        [QUERY_PARAMS.QUOTATION_DETAIL_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.ORDERS]: {
        [QUERY_PARAMS.ORDER_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.ORDER_DETAILS]: {
        [QUERY_PARAMS.ORDER_DETAIL_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.ORDER_LINES]: {
        [QUERY_PARAMS.ORDER_LINES_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.CUSTOMERS]: {
        [QUERY_PARAMS.USERS_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.CUSTOMER_DETAILS]: {
        [QUERY_PARAMS.CUSTOMER_USERS_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.NEWS]: {
        [QUERY_PARAMS.NEWS_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.EVENTS]: {
        [QUERY_PARAMS.EVENT_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.ALERTS]: {
        [QUERY_PARAMS.ALERT_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
    [ROUTES.ADMINISTRATION_HISTORY]: {
        [QUERY_PARAMS.HISTORY_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
    },
};

const ACCOUNT_PARAMS = {
    [QUERY_PARAMS.SHOW_API_KEY]: undefined,
    [QUERY_PARAMS.ADDRESSES_SHOW_HIDDEN]: undefined,
};

const PRODUCT_PARAMS = {
    [QUERY_PARAMS.ID_PRODUCT]: undefined,
    [QUERY_PARAMS.ID_VARIANT]: undefined,
};

const ORDER_DETAIL_PARAMS = {
    [QUERY_PARAMS.ID_ORDER]: undefined,
    [QUERY_PARAMS.ORDER_DETAIL_ORDER_BY]: undefined,
    [QUERY_PARAMS.ORDER_DETAIL_LIMIT]: undefined,
    [QUERY_PARAMS.ORDER_DETAIL_OFFSET]: undefined,
    [QUERY_PARAMS.ORDER_DETAIL_STATUS]: undefined,
};

const QUOTATION_DETAIL_PARAMS = {
    [QUERY_PARAMS.ID_QUOTATION]: undefined,
    [QUERY_PARAMS.QUOTATION_DETAIL_ORDER_BY]: undefined,
    [QUERY_PARAMS.QUOTATION_DETAIL_LIMIT]: undefined,
    [QUERY_PARAMS.QUOTATION_DETAIL_OFFSET]: undefined,
};

const REQUEST_DETAIL_PARAMS = {
    [QUERY_PARAMS.ID_REQUEST]: undefined,
    [QUERY_PARAMS.REQUEST_DETAIL_ORDER_BY]: undefined,
    [QUERY_PARAMS.REQUEST_DETAIL_LIMIT]: undefined,
    [QUERY_PARAMS.REQUEST_DETAIL_OFFSET]: undefined,
};

const NEWS_DETAIL_PARAMS = {
    [QUERY_PARAMS.ID_NEWS]: null,
};

const QUOTATION_MAKE_ORDER_PARAMS = {
    [QUERY_PARAMS.QUOTATION_ORDER_ID_CUSTOMER]: undefined,
    [QUERY_PARAMS.QUOTATION_ORDER_ITEMS]: undefined,
};

const CART_PARAMS = {
    [QUERY_PARAMS.ID_USER]: undefined,
    [QUERY_PARAMS.ID_QUOTATION]: undefined,
    [QUERY_PARAMS.ID_INVOICE_ADDRESS]: undefined,
    [QUERY_PARAMS.ID_DELIVERY_ADDRESS]: undefined,
    [QUERY_PARAMS.ID_TRANSPORT_TYPE]: undefined,
    [QUERY_PARAMS.ID_PAYMENT_METHOD]: undefined,
    [QUERY_PARAMS.DATE_REQUESTED]: undefined,
    [QUERY_PARAMS.CUSTOMER_REFERENCE]: undefined,
    [QUERY_PARAMS.CUSTOMER_NOTICES]: undefined,
    [QUERY_PARAMS.DEALER_NOTICES]: undefined,
    [QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE]: undefined,
    [QUERY_PARAMS.ORDER_FORM_ID_DST_STOCK]: undefined,
    [QUERY_PARAMS.ORDER_FORM_PROXY]: undefined,
    [QUERY_PARAMS.FINAL_ORDER_REFERENCE]: undefined,
    [QUERY_PARAMS.FINAL_USER_LANGUAGE]: undefined,
    [QUERY_PARAMS.FINAL_CUSTOMER_NAME]: undefined,
    [QUERY_PARAMS.FINAL_USER_FULLNAME]: undefined,
    [QUERY_PARAMS.FINAL_USER_EMAIL]: undefined,
    [QUERY_PARAMS.FINAL_USER_PHONE]: undefined,
    [QUERY_PARAMS.FINAL_ADDRESS_STREET]: undefined,
    [QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE]: undefined,
    [QUERY_PARAMS.FINAL_ADDRESS_CITY]: undefined,
    [QUERY_PARAMS.FINAL_ADDRESS_COUNTRY]: undefined,
    [QUERY_PARAMS.CART_STEP]: undefined,
    [QUERY_PARAMS.ORDER_FORM_TRADE]: undefined,
};

const CUSTOMER_USER_DETAIL_PARAMS = {
    [QUERY_PARAMS.USER_DETAILS_ID_USER]: undefined,
    [QUERY_PARAMS.SHOW_API_KEY]: undefined,
    [QUERY_PARAMS.ADDRESSES_SHOW_HIDDEN]: undefined,
};

const CUSTOMER_DETAILS_PARAMS = {
    [QUERY_PARAMS.ID_PAGE_CUSTOMER]: undefined,
    [QUERY_PARAMS.ADDRESSES_SHOW_HIDDEN]: undefined,
    [QUERY_PARAMS.CUSTOMER_USERS_ORDER_BY]: undefined,
};

const CUSTOMERS_PARAMS = {
    [QUERY_PARAMS.ID_PAGE_CUSTOMER]: undefined
};

const ADMIN_PARAMS = {
    [QUERY_PARAMS.ID_REPORT]: undefined,
    [QUERY_PARAMS.ID_FORM]: undefined,
};

const HISTORY_PARAMS = {
    [QUERY_PARAMS.HISTORY_CLASS]: undefined,
    [QUERY_PARAMS.HISTORY_METHOD]: undefined,
    [QUERY_PARAMS.HISTORY_LIMIT]: undefined,
};

const SHOPPING_LIST_PARAMS = {
    [QUERY_PARAMS.ID_SHOPPING_LIST]: undefined,
    [QUERY_PARAMS.SHOPPING_LIST_TOKEN]: undefined,
};


//enables to define default that are set when navigating to different route if they are not defined when calling
//navigation or by defaults of route(see ROUTE_QUERY_DEFAULTS)
export const ON_CHANGE_PATH_PARAMS = {
    ...ACCOUNT_PARAMS,
    ...ORDER_DETAIL_PARAMS,
    ...QUOTATION_DETAIL_PARAMS,
    ...REQUEST_DETAIL_PARAMS,
    ...NEWS_DETAIL_PARAMS,
    ...CART_PARAMS,
    ...QUOTATION_MAKE_ORDER_PARAMS,
    ...ADMIN_PARAMS,
    ...HISTORY_PARAMS,
    ...CUSTOMERS_PARAMS,
    ...CUSTOMER_DETAILS_PARAMS,
    ...CUSTOMER_USER_DETAIL_PARAMS,
    ...PRODUCT_PARAMS,
    ...SHOPPING_LIST_PARAMS,
};