import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {createFetchCustomerAddresses} from '../../backend/apiCalls';
import Address from '../general/Address';
import { UserAddress } from '../../constants/propTypesDefinitions';

/**
 * Provides filled user select. Users are filtered based on customer id. Users should be as defined in UserIndex
 * in propTypes.
 *
 * @fero
 */

class AddressSelectByCustomer extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        focus: PropTypes.bool,
        onChange: PropTypes.func,
        allowClear: PropTypes.bool,
        className: PropTypes.string,
        currentOption: UserAddress,
    };

    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
        }
    }

    componentDidMount() {
        this.fetchAddresses();
    }

    componentDidUpdate(prevProps) {
        const {customerId, userId} = this.props;
        const {customerId: prevCustomerId, userId: prevUserId} = prevProps;
        if(customerId != prevCustomerId || userId != prevUserId) {
            this.fetchAddresses();
        }
    }

    setFirstValue = () => {
        const { onChange, value, users, allowClear} = this.props;
        if(allowClear == false && users != null && users.length > 0 && value == null) {
            const firstValue = users[0].id;
            if(onChange != null)
                onChange(firstValue);
        }
        return null;
    };

    fetchAddresses = () => {
        const {customerId, userId, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        if(customerId != null)
        {
            fetchHandler(
                createFetchCustomerAddresses(),
                {id_customer: customerId, id_user: userId},
                (result) => {
                    this.setState({ addresses: result});
                }
            );
        }
        else
        {
            this.setState({addresses: []});
        }
    };

    render() {
        const {customerId, userId, currentOption, ...props} = this.props;
        const {addresses} = this.state;
        return <Select
            {...props}
            height={64}
            options={addresses.map(addr => {
                const addrChecked = addr != null ? addr : {};
                return {
                    value: addrChecked.id,
                    label: <Address address={addr}/>
                };
            })}
            currentOption={currentOption != null ? 
                {
                    value: currentOption.id,
                    label: <Address address={currentOption}/>
                }
                : null
            }
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(AddressSelectByCustomer);