import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';

/**
 * @fero
 */

class VehiclesSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.number,
        showHidden: PropTypes.bool,
        focus: PropTypes.bool,
    };

    static defaultProps = {
        showHidden: false,
    };

    render() {
        const { [GLOBAL_DATA.VEHICLES]: vehicles, showHidden, focus, ...props } = this.props;
        const vehiclesFiltered = showHidden ? vehicles : vehicles.filter(veh => veh.is_active);

        return <Select
            {...props}
            focus={focus}
            options={vehiclesFiltered.map(ch => {
                return {
                    label: ch.name + ' (' + ch.description + ')',
                    value: ch.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.VEHICLES])(VehiclesSelect);