/**
 * @author fero
 */

/*
* Matematically rounds to 2 decimal points(toFixed adds decimal zeroes if needed).
*/
export function roundTo2DecimalNumbers(number) {
    return Number(Math.round(number*100)/100).toFixed(2);
}

export function rationToPercent(number) {
    if(number >= 1) {
        const percentage = (1-(1/number))*100;
        return roundTo2DecimalNumbers(percentage);
    } else {
        const percentage = (number-1)*100;
        return roundTo2DecimalNumbers(percentage);
    }
}

export function rationToPercentSigned(ratio) {
    return (ratio >= 1 ? '+' : '') + rationToPercent(ratio) + '%';
}

export function percentToRation(number) {
    if(number >= 0) {
        return Number((1/(1-(number/100))).toFixed(4));
    } else {
        return Number((1+(number/100)).toFixed(4));
    }
}

export function percentageFromPriceDifference(salePrice, originalPrice) {
    if(salePrice > originalPrice) {
        const percentage = (1-(originalPrice/salePrice)) * 100;
        return roundTo2DecimalNumbers(percentage);
    } else {
        const percentage = ((salePrice/originalPrice)-1) * 100;
        return roundTo2DecimalNumbers(percentage);
    }
}

export function applyDiscount(price, discount) {
    if(price == null)
        return null;

    const priceVal = Number(price);

    if(Number.isNaN(priceVal))
        return null;

    return Number(priceVal*percentToRation(-discount)).toFixed(2);
}