import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import LogInForm from './LogInForm';
import generalFailedPC from '../fetch/generalFailedPC';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { QUERY_PARAMS } from '../../constants/navigation';

/**
 * @fero
 */

class Login extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.RELOAD_RIGHTS]: PropTypes.func.isRequired,
    };

    onSubmitFinished = () => {
        const {[SESSION_ATTRIBUTES.RELOAD_RIGHTS]: reloadRights} = this.props;
        reloadRights(null, null, true); // reset locale
    };

    render() {
        return <FormModal
            className="full-size-width"
            openNode={
                <Button icon="login">
                    <span className="ml-2"><Trans>Prihlásenie</Trans></span>
                </Button>
            }
            values={{}}
            onFinishSuccessful={this.onSubmitFinished}
            title={<Trans>Prihlásenie</Trans>}
            Form={LogInForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa prihlásiť`)}
        />;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RELOAD_RIGHTS])(Login);