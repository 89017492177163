import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import Login from '../login/Login';
import Register from '../login/Register';
import HeaderTabs from './header/HeaderTabs';
import {locationHOC} from '../locationProvider/locationHOC';
import { getRelativePath} from '../../lib/url';
import {Trans, t} from '@lingui/macro';
import { Button, Drawer, Icon} from 'antd';
import {RIGHTS} from '../../constants/Rights';
import {GLOBAL_DATA} from '../../constants/globalData';
import {UserInfo} from '../../constants/propTypesDefinitions';
import withDataHOC from '../dataProvider/withDataHOC';
import { formatUserInfoName } from '../../lib/formatting';
import LogoutButton from '../login/LogoutButton';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';

/**
 * @dusan
 */
class SidePanel extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
        location: PropTypes.object.isRequired,
        [SESSION_ATTRIBUTES.DISABLE_MOBILE]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    componentDidUpdate(prevProps) {
        const {location: prevLocation} = prevProps;
        const {location} = this.props;
        const prevPath = getRelativePath(prevLocation.pathname);
        const path = getRelativePath(location.pathname);
        if(prevPath != path) {
            this.setState({visible: false});
        }
    }

    showPanel = () => {
        this.setState({visible: true});
    }

    hidePanel = () => {
        this.setState({visible: false});
    }

    switchToDesktop = () => {
        const {[SESSION_ATTRIBUTES.DISABLE_MOBILE]: disableMobile} = this.props;
        disableMobile();
    }

    render() {
        const {[GLOBAL_DATA.ACCOUNT_INFO]: accountInfo} = this.props;
        const {visible} = this.state;
        return <div>
            <Button
                className="m-2 side-panel-button"
                onClick={this.showPanel}
            >
                <Icon type="unordered-list"/>
            </Button>
            <Drawer
                title={<Trans>Navigácia</Trans>}
                placement="left"
                closable={true}
                onClose={this.hidePanel}
                visible={visible}
            >
                <div className="d-flex flex-column align-items-center side-panel">
                    <RightsWrapper from={RIGHTS.PENDING}>
                        <div className="pb-3 d-flex align-items-center">
                            <span className="pr-2"><Trans>Prihlásený:</Trans></span>
                            <span className="font-weight-bold">{formatUserInfoName(accountInfo)}</span>
                        </div>
                    </RightsWrapper>
                    <RightsWrapper to={RIGHTS.UNREGISTERED}>
                        <div className="py-2 full-size-width text-center">
                            <Login/>
                            <Trans>alebo</Trans>
                            <Register/>
                        </div>
                    </RightsWrapper>
                    <RightsWrapper from={RIGHTS.PENDING}>
                        <LogoutButton/>
                        <HeaderTabs/>
                    </RightsWrapper>
                    <Button
                        icon="desktop"
                        onClick={this.switchToDesktop}
                    >
                        <span className="ml-2"><Trans>Verzia pre PC</Trans></span>
                    </Button>
                </div>
            </Drawer>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.DISABLE_MOBILE])(
    locationHOC(withDataHOC([GLOBAL_DATA.ACCOUNT_INFO])(SidePanel))
);