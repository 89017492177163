import React from 'react';
import PropTypes from 'prop-types';
/**
 * @fero
 */

class Failed extends React.PureComponent {
    static propTypes = {
        message: PropTypes.node,
        errorMessage: PropTypes.string,
    };

    render() {
        const {errorMessage, message, className} = this.props;

        return <div className={"text-center " + (className != null ? className : "")}>
            <h4>{message}</h4>
            <div className="text-danger">{errorMessage}</div>
        </div>;
    }

}

export default Failed;