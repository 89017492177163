import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../general/Tooltip';
import {Trans, t} from '@lingui/macro';

/**
 * Hack tooltip component because of overflow ellipsis of second first child. Find out if label of control component(second first child)
 * overflows and if so adds a tooltip and ellipsis overflow.
 *
 * @fero
 */

class ControlWrapper extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            overflow: false,
        }
    }

    componentDidMount() {
        this.setOverflowState();
    }

    componentDidUpdate() {
        this.setOverflowState();
    }

    setOverflowState = () => {
        if (
            this.node.current == null ||
            this.node.current.firstChild == null ||
            this.node.current.firstChild.firstChild == null
        ) {
            this.setState({
                overflow: false,
            });
        } else {
            const controlElement = this.node.current.firstChild.firstChild;
            if (controlElement != null && controlElement.offsetWidth < controlElement.scrollWidth) {
                this.setState({
                    overflow: true,
                });
            } else {
                this.setState({
                    overflow: false,
                });
            }
        }
    };

    render() {
        const {children, title} = this.props;
        const {overflow} = this.state;
        return <Tooltip title={overflow ? title : null} placement={'right'}>
            <div
                ref={this.node}
                className="first-grandchild-text-overflow-ellipsis"
            >
                {children}
            </div>
        </Tooltip>
    }

}

export default ControlWrapper;