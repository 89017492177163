
import {message} from 'antd';
import {RIGHTS} from '../constants/Rights';
import {TRADE} from '../constants/trade';
import { ORDER_STATE, ORDER_LINE_STATE } from '../constants/orderStates';
import { QUOTATION_STATE } from '../constants/quotationStates';
import { REQUEST_STATE } from '../constants/requestStates';
import { TRANSPORT_TYPE } from '../constants/transportTypes';
import { PACKAGE_TYPE } from '../constants/packageTypes';
import { roundTo2DecimalNumbers } from './computation';
import { currentDate, dateDiff } from './date';
/**
 * @author fero
 */

/*
* This method serves as a search method for select in which as option children are string or Trans localization component.
*/
export function searchTextOrTrans(input, option) {
    let optionText = null;
    if (
        option.props.children != null &&
        option.props.children.toLowerCase != null
    ) {
        optionText = option.props.children
    } else {
        if (
            option.props.children != null &&
            option.props.children.props != null &&
            option.props.children.props.id != null &&
            option.props.children.props.id.toLowerCase != null
        ) {
            optionText = option.props.children.props.id;
        } else {
            return false;
        }
    }
    return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export function getAddressById(addresses, addressId) {
    return addresses.find(address => address.id == addressId);
};

export function getParentsIds(categoryId) {
    let ids = [];
    if(categoryId != null && categoryId.length != null) {
        let restOfString = categoryId;
        while (restOfString.length >= 3) {
            restOfString = restOfString.substring(0, restOfString.length - 2);
            ids.push(restOfString);
        }
    }
    return ids;
}
export function getParentId(categoryId) {
    let id =categoryId;
    if(
        id != null &&
        id.length != null &&
        !Number.isNaN(Number(id.length)) &&
        id.length >= 3)
    {
        return id.substring(0, id.length - 2);
    } else {
        return null;
    }
}

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function checkImage(file) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJPG) {
        message.error('You can only upload JPG or PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJPG && isLt2M;
}

export function createParameterObject(options) {
    let obj = {};
    if(options != null && options.length != null) {
        options.map(option => {
            if(option != null) {
                obj[option.id_parameter] = option.val;
            }
        });
    }
    return obj;
}

export function parseObjectFromQueryString(obj) {
    return obj != null ? JSON.parse(obj) : {};
}

export function priceByProductCount(priceData, count, packageTypeId, stockOptions, stockId) {
    if (priceData == null)
        return null;

    const {quantity_min, quantity_bulk, quantity_pallet, price_min, price_bulk, price_pallet, special_price} = priceData;
    const quantity = count != null && !Number.isNaN(Number(count)) ? Number(count) : Number(quantity_min);
    
    let unitPrice = null;
    if(special_price != null)
        unitPrice = special_price;
    else if(price_pallet != null && quantity_pallet != null && !Number.isNaN(quantity_pallet) && quantity >= Number(quantity_pallet))
        unitPrice = price_pallet;
    else if(price_bulk != null && quantity_bulk != null && !Number.isNaN(quantity_bulk) && quantity >= Number(quantity_bulk))
        unitPrice = price_bulk;
    else
        unitPrice = price_min;

    if(stockId != null)
    {
        const stock = stockOptions != null ? stockOptions.find(opt => opt.id_stock == stockId) : null;
        if(stock != null && stock.unit_price != null && Number(stock.unit_price) > unitPrice)
            unitPrice = stock.unit_price;
    }

    if(unitPrice == null)
        return null;
    else if(packageTypeId == PACKAGE_TYPE.BULK && quantity_bulk != null)
        return roundTo2DecimalNumbers(Number(unitPrice) * Number(quantity_bulk));
    else if(packageTypeId == PACKAGE_TYPE.PALLET && quantity_pallet != null)
        return roundTo2DecimalNumbers(Number(unitPrice) * Number(quantity_pallet));
    else
        return unitPrice;
};

export function taxMultiplier(tax) {
    return !Number.isNaN(Number(tax)) ? (1 + Number(tax)) : 1;
}

export function hasRights(rights, rightsFrom, rightsTo) {
    if(rights == null) {
        return false;
    } else {
        const fromChecked = rightsFrom != null ? rightsFrom : RIGHTS.LOCKED;
        const toChecked = rightsTo != null ? rightsTo : RIGHTS.ADMIN;
        return rights <= toChecked && rights >= fromChecked
    }
}

export function getPartByObjectNavigation(object, navigation) {
    let tmp = object;
    if(navigation != null && !Number.isNaN(Number(navigation.length)) && navigation.length > 0) {
        for(let i = 0; i < navigation. length; i++) {
            tmp = tmp[navigation[i]];
            if(tmp == null && i < (navigation.length-1)) {
                return undefined;
            }
        }
    }
    return tmp;
};

export function focus(ref) {
    setTimeout(() => {
        if(ref != null && ref.focus != null && ref.focus && typeof ref.focus === "function")
        ref.focus();
    }, 0)
}

export function getInputOnChangeEventValue(e) {
    return e != null && e.target != null && e.target.value != null ? e.target.value : null;
}

export function getInputOnChangeEventChecked(e) {
    return e != null && e.target != null && e.target.checked != null ? e.target.checked : null;
}

export function isRequestSend(requestDetails) {
    return requestDetails.id_status != REQUEST_STATE.DRAFT;
}

export function isRequestEmpty(requestDetails) {
    return requestDetails.lines == null || requestDetails.lines.length == 0;
}

export function isRequestFinished(requestDetails) {
    return requestDetails.id_quotation != null;
}

export function isQuotationSend(quotationDetails) {
    return quotationDetails.id_status != QUOTATION_STATE.DRAFT;
}

export function isQuotationActive(quotationDetails) {
    return quotationDetails.active_to == null || dateDiff(quotationDetails.active_to, currentDate()) >= 0;
}

export function isQuotationEmpty(quotationDetails) {
    return quotationDetails.lines == null || quotationDetails.lines.length == 0;
}

export function isOrderSend(orderDetails) {
    return orderDetails.id_status != ORDER_STATE.DRAFT;
}

export function isOrderFinished(orderDetails) {
    return orderDetails.id_status == ORDER_STATE.FINISHED;
}

export function isOrderLineFinished(data) {
    return data.id_status == ORDER_LINE_STATE.FINISHED;
}

export function isOrderEmpty(orderDetails) {
    return orderDetails.lines == null || orderDetails.lines.length == 0;
}

export function isOrderRemote(orderDetails) {
    return orderDetails.is_remote != 0;
}

export function isOrderDivisible(orderDetails) {
    return orderDetails.is_divisible != 0;
}

export function isTradeSell(trade) {
    return trade == TRADE.SELL;
}

export function isAddressRequired(rights, transportTypeId) {
    return (rights < RIGHTS.MARKETING || (transportTypeId != null && transportTypeId != TRANSPORT_TYPE.AT_STORE));
}

export function areAllProductsBind(detail) {
    let allBind = true;
    if(detail != null && detail.lines != null && detail.lines.length > 0) {
        const lines = detail.lines;
        for(let i = 0; i < lines.length; i++) {
            if(lines[i].id_product == null) {
                allBind = false;
            }
        }
    }
    return allBind;
}

export function getDefaultTablePageSize() {
    return !Number.isNaN(Number(process.env.DEFAULT_TABLE_PAGE_SIZE)) ? Number(process.env.DEFAULT_TABLE_PAGE_SIZE) : 20
}

export function getQuotationLines(quotationDetails) {
    return (quotationDetails != null && quotationDetails.lines != null) ? quotationDetails.lines : [];
};

export function getQuotationPricesBySelectedItems(quotationDetails, selectedItemIds, overrideQuantities = {}) {
    if(quotationDetails == null)
        return {};

    if(selectedItemIds == null)
    {
        // all selected
        return {
            sum_netto: quotationDetails.sum_netto,
            sum_brutto: quotationDetails.sum_brutto,
            total_mass: quotationDetails.total_mass,
        };
    }
    
    const lines = getQuotationLines(quotationDetails);
    let netto = {};
    if(quotationDetails.transport_price != null)
    {
        if(netto[quotationDetails.transport_tax_rate] == null)
            netto[quotationDetails.transport_tax_rate] = 0.0; 

        netto[quotationDetails.transport_tax_rate] += Number(quotationDetails.transport_price);
    }

    if(quotationDetails.payment_price != null) 
    {
        if(netto[quotationDetails.payment_tax_rate] == null)
            netto[quotationDetails.payment_tax_rate] = 0.0; 

        netto[quotationDetails.payment_tax_rate] += Number(quotationDetails.payment_price);
    }

    let total_mass = 0;

    selectedItemIds.forEach(id => {
        const line = lines.find(l => l.id_item === id);
        if(line != null) {
            const quantity = Number(overrideQuantities[id] || line.quantity);
            const unitPrice = line.unit_price != null ? Number(line.unit_price) : 0;
            const linePrice = Number(roundTo2DecimalNumbers(unitPrice * quantity));
            const lineMass = line.mass != null ? Number(line.mass) * quantity : 0;

            if(netto[line.tax_rate] == null)
                netto[line.tax_rate] = 0.0;

            netto[line.tax_rate] += linePrice;
            total_mass += lineMass;
        }
    });
    let sum_netto = 0;
    let sum_brutto = 0;


    Object.entries(netto).forEach(([tax_rate, sum_val]) => {
        sum_netto += sum_val;
        sum_brutto += sum_val * (1 + Number(tax_rate));
    });

    return {
        sum_netto: roundTo2DecimalNumbers(sum_netto),
        sum_brutto: roundTo2DecimalNumbers(sum_brutto),
        total_mass: total_mass,
    };
}

export function customerIsSupplier(customerDetails) {
    return customerDetails != null && Array.isArray(customerDetails.id_manufacturer) && customerDetails.id_manufacturer.length > 0;
}

export function getBackgroundStyle(trade, flags = {}) {
    if(flags.isAutoSupply || trade == TRADE.BUY)
        return 'light-blue-background';
    else if(flags.isNotCovered)
        return 'light-red-background';
    else if(flags.isService)
        return 'light-green-background';
    else if(flags.isExternal || flags.isSale)
        return 'light-yellow-background';
    else
        return 'white-background';
}

export function getInvoiceBackgroundStyle(invoice) {
    if(invoice.overdue > 0)
        return 'light-red-background';
    else if(invoice.is_paid == 0)
        return 'light-yellow-background';
    else
        return 'white-background';
}