import React from 'react';
import PropTypes from 'prop-types';
import Exception from './Exception';
import {Button, notification} from 'antd'
import {ASYNC_STATE} from '../../constants/AsyncState';

/**
 * @fero
 */

class ButtonFetch extends React.PureComponent {
    static propTypes = {
        values: PropTypes.object.isRequired,
        Response: PropTypes.func,
        Failed: PropTypes.func,
        fetchFunction: PropTypes.func.isRequired,
        onFinish: PropTypes.func,//on successful finish only, same as onFinishSuccessful of fetchHandler
        onAfterFetch: PropTypes.func,//performed always after fetch, same as on finish in fetchHandler(onFinish was taken)
        shouldFetch: PropTypes.bool,//if defined, fetches only if true
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    handleSubmit = (e) => {
        if(e != null) {
            e.preventDefault();
        }
        const {fetchFunction, Response, Failed, values, onFinish, shouldFetch, onAfterFetch} = this.props;
        if(shouldFetch == null || shouldFetch == true) {
            this.setState({loading: true});

            fetchFunction().then((response) => {
                this.setState({loading: false});
                switch (response.asyncState) {
                    case ASYNC_STATE.SUCCEEDED :
                        if(Response != null)
                        {
                            notification['success']({
                                duration: 1.5,
                                message: <Response
                                    fetchResult={response.result}
                                    values={values}
                                />,
                            });
                        }
                        if(onFinish != null)
                            onFinish(response.result);
                        break;

                    case ASYNC_STATE.FAILED :
                        if(Failed != null)
                        {
                            notification['error']({
                                duration: 3,
                                message: <Failed
                                    result={response.result}
                                    values={values}
                                />,
                            });
                        }
                        break;

                    case ASYNC_STATE.EXCEPTION :
                        notification['error']({
                            duration: 3,
                            message: <Exception response={response}/>,
                        });
                        break;

                    default :
                        notification['error']({
                            duration: 3,
                            message: <div>ERROR IN FRONT END, TRY TO RELOAD PAGE, OR CONTACT SUPPORT</div>,
                        });
                }

                if(onAfterFetch != null) {
                    onAfterFetch();
                }

                this.setState({loading: false});
            });
        }
    };

    render() {
        const {values, Response, Failed, fetchFunction, onFinish, shouldFetch, onAfterFetch, children, ...props} = this.props;
        const {loading} = this.state;
        return <Button onClick={this.handleSubmit} loading={loading} {...props}>
            {children}
        </Button>;
    }

}

export default ButtonFetch;