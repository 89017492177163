import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import {Trans, t} from '@lingui/macro';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { Button } from 'antd';

/**
 * @fero
 */

class LogoutButton extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.LOGOUT]: PropTypes.func.isRequired,
    };

    render() {
        const {[SESSION_ATTRIBUTES.LOGOUT]: logout} = this.props;
        return <Button
            type="danger"
            icon="logout"
            onClick={logout}
        >
            <span className="ml-2"><Trans>Odhlásiť</Trans></span>
        </Button>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.LOGOUT])(LogoutButton);