import React from 'react';
import Link from '../navigation/Link';
import {Trans, t} from '@lingui/macro';
import {ROUTES} from '../../constants/navigation';

/**
 * @fero
 */

class Footer extends React.PureComponent {

    render() {
        return <div className="footer">
            <Link className="px-2 text-dark footer-link" to={ROUTES.COMMERCIAL_TERMS}><Trans>Obchodné podmienky</Trans></Link>
            <Link className="px-2 text-dark footer-link" to={ROUTES.PERSONAL_DATA_PROTECTION}><Trans>Zásady ochrany osobných údajov</Trans></Link>
            <Link className="px-2 text-dark footer-link" to={ROUTES.CLAIM_RULES}><Trans>Reklamačný poriadok</Trans></Link>
        </div>;
    }

}

export default Footer;