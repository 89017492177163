import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * Component that enables input text. onChange event is fired only
 * when return is pressed or when focus is lost.
 *
 * @fero
 */

class InputText extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
        type: PropTypes.string,
        autoFocus: PropTypes.bool,
        allowClear: PropTypes.bool,
    };

    static defaultProps = {
        type: "text",
        autoFocus: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            itsValue: props.value,
            itsLastValue: props.value,
        };
    }

    componentDidMount() {
        const {value, autoFocus} = this.props;
        this.setState({
            itsValue: value,
            itsLastValue: value,
        });
        
        if(autoFocus)
        {
            setTimeout(() => {
                this.focus();
            }, 0)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {value} = this.props;
        const {itsLastValue} = this.state;
        const {itsLastValue: itsPrevValue} = prevState;
        if(itsPrevValue == itsLastValue && value !== itsLastValue)
        {
            this.setState({
                itsValue: value,
                itsLastValue: value,
            });
        }
    } 

    focus() {
        if(this.input != null && this.input.focus != null) 
            this.input.focus();
    }

    onChanged = (ev) => {
        const {onChange, allowClear} = this.props;
        const valueChecked = ev != null && ev.target!= null ? ev.target.value : null;
        const valueStringToNull = valueChecked != "" ? valueChecked : null;
        this.setState({itsValue: valueStringToNull});

        if(valueStringToNull == null && allowClear && onChange != null)
            onChange(null);
    };

    onBlur = () => {
        const {itsValue, itsLastValue} = this.state;
        const {onChange} = this.props;
        if(itsValue != itsLastValue) {
            this.setState({ itsLastValue: itsValue });

            if(onChange != null)
                onChange(itsValue);
        }
    };

    render() {
        const {value, onChange, type, autoFocus, placeholder, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile, ...props} = this.props;
        const {itsValue} = this.state;
        
        return <Input
            {...props}
            placeholder={placeholder}
            onChange={this.onChanged}
            onBlur={this.onBlur}
            onPressEnter={this.onBlur}
            type={type}
            value={itsValue}
            ref={node => this.input = node}
        />;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(InputText);