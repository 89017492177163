import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import {Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import ChangeRightsForm from './ChangeRightsForm';

/**
 * @dusan
 */

class ChangeRights extends React.PureComponent {
    static propTypes = {
        onFinish: PropTypes.func,
    };

    render() {
        const {onFinish} = this.props;
        return <FormModal
            className="full-size-width"
            openNode={
                <div className="full-size-width">
                    <Icon type="retweet" className="pr-2"/>
                    <Trans>Zmeniť pohľad</Trans>
                </div>
            }
            values={{}}
            title={<Trans>Zmeniť pohľad</Trans>}
            Form={ChangeRightsForm}
            Response={null}
            onFinishSuccessful={onFinish}
            Failed={generalFailedPC(t`Nepodarilo sa zmeniť pohľad`)}
        />;
    }

}

export default ChangeRights;