import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';

/**
 * @fero
 */

const defaultCountry = 'SK';

class CountriesSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
    };

    componentDidMount() {
        const {onChange, value } = this.props;
        if(value == null) {
            onChange(defaultCountry);
        }
    }

    render() {
        const { countries, ...props } = this.props;
        return <Select
            {...props}
            options={countries.map(country => {
                return {
                    label: country.name,
                    value: country.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.COUNTRIES])(CountriesSelect);