// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { getQueryParam } from '../../lib/url';
import { OCI_HOOK_COOKIE, QUERY_PARAMS } from '../../constants/navigation';
import { withCookies } from 'react-cookie';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { navigate } from 'gatsby';

/**
 * @fero
 */

class LocationProvider extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        [SESSION_ATTRIBUTES.GTAG]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            location: props.location,
            goBack: this.goBack,
            ociHook: this.getOciHook(),
        }
    }

    static getDerivedStateFromProps(props) {
        return {
            location: props.location,
        }
    }

    componentDidUpdate(prevProps) {
        const {location, [SESSION_ATTRIBUTES.GTAG]: gtag, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        const {location: prevLocation} = prevProps;
        if(prevLocation.pathname != location.pathname || prevLocation.search != location.search)
        {
            if(settings != null && settings.google_analytics_code != null)
            {
                gtag('config', settings.google_analytics_code, {
                    'send_page_view': false,
                    'page_referrer': prevLocation.href,
                    'page_location': location.href,
                    'update': true,
                });
                gtag('event', 'page_view');
            }
        }
    }

    goBack = () => {
        navigate(-2);
    };

    getOciHook = () => {
        const {location, cookies} = this.props;
        const urlHook = getQueryParam(location, QUERY_PARAMS.OCI_HOOK);
        if(urlHook != null && urlHook != "")
        {
            // set cookie
            cookies.set(OCI_HOOK_COOKIE, urlHook, {path: '/'});
            return urlHook;
        }

        return cookies.get(OCI_HOOK_COOKIE);
    }

    render() {
        const {children} = this.props;
        return <LocationContext.Provider value={this.state}>
            {children}
        </LocationContext.Provider>;
    }

}

export default withCookies(withSessionHOC([SESSION_ATTRIBUTES.GTAG, SESSION_ATTRIBUTES.SETTINGS])(LocationProvider));

export const LocationContext = React.createContext({
    location: {},
    history: {},
    goBack: () => {},
    ociHook: "",
});