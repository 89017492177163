import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import {locationHOC} from '../../locationProvider/locationHOC';
import lockHOC from '../../dataProvider/lockHOC';
import Select from '../../general/Select';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation'
import {navigateToParametrized, getQueryParam, getRelativePath} from '../../../lib/url';
import {Trans, t} from '@lingui/macro';
import CustomerNotes from '../../project/CustomerNotes';
import { CustomersArray, Customer } from '../../../constants/propTypesDefinitions';
import { Button, Dropdown, Icon, Menu } from 'antd';
import { RIGHTS } from '../../../constants/Rights';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Tooltip from '../../general/Tooltip';
import CustomerEvents from '../../project/CustomerEvents';
import CustomerDetailsLink from './CustomerDetailsLink';
import Finstat from '../../customers/customerDetails/Finstat';
import Price from '../../general/Price';

/**
 * @fero
 */
const ROUTES_WITH_CUSTOMER_SELECT = [
    ROUTES.HOME,
    ROUTES.CONTRACT,
    ROUTES.PRODUCT_DETAILS,
    ROUTES.CART,
    ROUTES.DISCOUNT_RULES,
];

class CustomersSelectHeader extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        [GLOBAL_DATA.CUSTOMERS]: CustomersArray.isRequired,
    };

    isHiddenOnRoute = () => {
        const {location} = this.props;
        const relativePath = getRelativePath(location.pathname);
        return !ROUTES_WITH_CUSTOMER_SELECT.includes(relativePath);
    };

    compareCustomer(option, search) {
        const customerName = option.label != null ? option.label : '';
        const regex = /[\u0300-\u036f\s\-\(\)\,\.\*]+/ig;
        const haystack = customerName.normalize("NFD").replace(regex, '').toLowerCase();
        const needle = search.normalize("NFD").replace(regex, '').toLowerCase();
        return haystack.includes(needle);
    }

    onChange = (customerId) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ID_CUSTOMER]: customerId,
            [QUERY_PARAMS.ID_USER]: null,
            [QUERY_PARAMS.CONTRACT_ID_MANUFACTURER]: null,
            [QUERY_PARAMS.CONTRACT_DESIGNATION]: null,
        });
    };

    render() {
        const {[GLOBAL_DATA.CUSTOMERS]: customers, isLocked, location} = this.props;
        const customerId = getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER);
        const valueToNumber = customerId != null ? Number(customerId) : null;
        const customer = customers.find(obj => obj.id == customerId);
        const isHidden = this.isHiddenOnRoute();
        return !isHidden && customers.length > 1 ?
            <div className="d-flex flex-grow-1 justify-content-center align-items-center p-3">
                <div className="pr-2">
                    <Trans>Cenová hladina </Trans>
                </div>
                <Select
                    className="main-customer-select"
                    options={customers.map(customer => {
                        return {
                            label: customer.name,
                            value: customer.id,
                        }
                    })}
                    value={valueToNumber}
                    onChange={this.onChange}
                    disabled={isLocked}
                    compareFunc={this.compareCustomer}
                    tooltiped={true}
                />
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <CustomerDropDown
                        customer={customer}
                    />
                    { customer != null && customer.overdue_saldo != null && Number(customer.overdue_saldo) > 0 ?
                        <Tooltip title={
                            <span>
                                <Trans>Zákazník má faktúry s hodnotou</Trans>
                                <Price price={customer.overdue_saldo} className="px-2"/>
                                <Trans>po splatnosti</Trans>
                            </span>
                        }>
                            <div className="p-3">
                                <Icon type="warning" theme="twoTone" twoToneColor="#ffbf00"/>
                            </div>
                        </Tooltip> : null
                    }
                </RightsWrapper>
                
            </div> : null;
    }

}

class CustomerDropDown extends React.PureComponent {
    static propTypes = {
        customer: Customer,
    };

    render() {
        const {customer} = this.props;
        if(customer == null)
            return null;

        const menu = <Menu>
            <CustomerDetailsLink
                key="details" 
                className="pz-3 py-2 list-item"
                customer={customer}
            />
            <Finstat
                key="finstat" 
                className="pz-3 py-2 list-item"
                customer={customer}
                type="menu-item"
            />
            <Menu.Item key="events" className="pz-3 py-2 list-item">
                <CustomerEvents
                    customer={customer}
                    type="link"
                />
            </Menu.Item>
            <Menu.Item key="notes" className="pz-3 py-2 list-item">
                <CustomerNotes 
                    customer={customer}
                    type="link"
                />
            </Menu.Item>
        </Menu>;

        return <Dropdown overlay={menu} placement="bottomRight">
                <Button icon="unordered-list"/>
        </Dropdown>;
        
    }

}

export default locationHOC(lockHOC(withDataHOC([GLOBAL_DATA.CUSTOMERS])(CustomersSelectHeader)));