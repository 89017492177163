import {merge} from '../lib/object';

export const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};
export const formSubItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
};

export const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    }
};

export const max99Rule = {
    validator: (rule, value, callback) => {
        if (value != null && !Number.isNaN(Number(value))) {
            if (Number(value) < 100) {
                callback();
            } else {
                callback(true);
            }
        } else {
            callback();
        }
    },
    message: 'Maximum je 99.',
};

export const max100Rule = {
    validator: (rule, value, callback) => {
        if (value != null && !Number.isNaN(Number(value))) {
            if (Number(value) <= 100) {
                callback();
            } else {
                callback(true);
            }
        } else {
            callback();
        }
    },
    message: 'Maximum je 100.',
};

export const min100Rule = {
    validator: (rule, value, callback) => {
        if (value != null && !Number.isNaN(Number(value))) {
            if (Number(value) >= -100) {
                callback();
            } else {
                callback(true);
            }
        } else {
            callback();
        }
    },
    message: 'Minimum je -100.',
};

export const mandatoryRule = {
    required: true, message: 'Povinné',
};

export const optionalRule = {
    required: false, message: '',
};

export const notFalseRule = {
    validator: (rule, value, callback) => {
        if (value != null && value) {
            callback();
        } else {
            callback(true);
        }
    },
    required: true,
    message: 'Povinné',
};


export const numberMandatoryRule = {
    validator: (rule, value, callback) => {
        if (
            (value === 0 || value != null) && matchNumber(value)
        ) {
            callback();
        } else {
            callback(true);
        }
    },
    required: true,
    message: 'Musí byť zadané číslo.',
};


export const numberRule = {
    validator: (rule, value, callback) => {
        if (value) {
            if (matchNumber(value)) {
                callback();
            } else {
                callback(true);
            }
        } else {
            callback();
        }

    },
    message: 'Musí byť zadané číslo.',
};

export const max255Rule = {
    max: 255,
    message: 'Presiahnutá maximálna dĺžka',
};

export const createRequireParameterIds = (requiredParameterIds) => {
    return {
        validator: (rule, value, callback) => {
            const parameterIds = value.map(parameter => {
                return parameter.id_parameter;
            });
            if (parameterIds.every(parameterId => requiredParameterIds.includes(parameterId))) {
                callback()
            } else {
                callback(true);
            }

        },
        message: 'Neobsahuje povinný parameter.',
    }
};

export const createRequireParameterValues = (requiredParameterIds) => {
    return {
        validator: (rule, value, callback) => {
            const requiredParameters = value.filter(parameter => requiredParameterIds.includes(parameter.id_parameter));
            const requiredParameterValues = requiredParameters.map(parameter => {
                return parameter.val;
            });
            if (requiredParameterValues.includes(null)) {
                callback(true)
            } else {
                callback();
            }

        },
        message: 'Všetky povinné parametre musia mať definovanú hodnotu.',
    }
};

export const parameterValuesNotNull = {
    validator: (rule, value, callback) => {
        const requiredParameterValues = value.map(parameter => {
            return parameter.val;
        });
        if (requiredParameterValues.includes(null)) {
            callback(true)
        } else {
            callback();
        }

    },
    message: 'Všetky parametre musia mať definovanú hopdnotu.',
};


function matchNumber(value) {
    return value.toString().match(/(^[0-9]+[.,]?[0-9]*$)/g) != null ||
        value.toString().match(/(^-[0-9]+[.,]?[0-9]*$)/g) != null;
}

//types of customization form

export const FORM_TYPE_BOOL = 'B';
export const FORM_TYPE_ENUM = 'E';
export const FORM_TYPE_NUMERIC = 'N';

export const FORM_TYPES = {
    FORM_TYPE_NUMERIC: {
        key: FORM_TYPE_NUMERIC,
        label: 'NUMERIC',
    },
    FORM_TYPE_ENUM: {
        key: FORM_TYPE_ENUM,
        label: 'ENUM',
    },
    FORM_TYPE_BOOL: {
        key: FORM_TYPE_BOOL,
        label: 'BOOLEAN',
    }
};

// validateTrigger: 'onSubmit'