import React from 'react';
import FormModal from '../fetch/FormModal';
import RegisterForm from './RegisterForm';
import RegisterResponse from './RegisterResponse';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class Register extends React.PureComponent {
    render() {
        return <FormModal
            className="full-size-width"
            openNode={
                <Button icon="user-add">
                    <span className="ml-2"><Trans>Registrácia</Trans></span>
                </Button>
            }
            values={{}}
            title={<Trans>Registrácia</Trans>}
            Form={RegisterForm}
            Response={RegisterResponse}
            Failed={generalFailedPC(t`Nepodarilo sa zaregistrovať`)}
        />;
    }
}

export default Register;