import React from 'react';
import PropTypes from 'prop-types';
import {locationHOC} from '../../locationProvider/locationHOC';
import {Trans, t} from '@lingui/macro';
import {navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import { Customer } from '../../../constants/propTypesDefinitions';
import { Icon, Menu } from 'antd';

/**
 * @dusan
 */

class CustomerDetailsLink extends React.PureComponent {
    static propTypes = {
        customer: Customer.isRequired,
    };

    render() {
        const {location, customer = {}, ...props} = this.props;
        return <Menu.Item {...props}>
            <div 
                className="full-size-width" 
                onClick={() => {
                    navigateToParametrized(location, ROUTES.CUSTOMER_DETAILS, { [QUERY_PARAMS.ID_PAGE_CUSTOMER]: customer.id})
                }}
            >
                <Icon type="idcard" className="pr-2"/>
                <Trans>Detail organizácie</Trans>
            </div>
        </Menu.Item>;
    }

}

export default locationHOC(CustomerDetailsLink);