import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import {Trans, t} from '@lingui/macro';
import DisplayModal from '../general/DisplayModal';
import { Customer } from '../../constants/propTypesDefinitions';
import EventsList from '../events/EventsList';

/**
 * Provides chat about customers with multiple threads
 *
 * @dusan
 */

class CustomerEvents extends React.PureComponent {
    static propTypes = {
        customer: Customer,
        type: PropTypes.string,
    };

    render() {
        const {customer, type} = this.props;
        const customerChecked = customer != null ? customer : {};
        
        return <DisplayModal
            className={type == 'link' ? 'full-size-width' : undefined}
            openNode={
                type == 'link' ?
                    <div className="full-size-width">
                        <Icon type="car" className="pr-2"/>
                        <Trans>Eventy</Trans>
                    </div>
                    : <Button>
                        <Icon type="car" className="pr-2"/>
                        <Trans>Eventy</Trans>
                    </Button>
            }
            title={
                <div className="d-flex flex-wrap align-items-center">
                    <Trans>Eventy týkajúce sa</Trans>{' '}{customerChecked.name}
                </div>
            }
            modalClassName="middle-modal"
        >
            <EventsList
                customerId={customerChecked.id}
            />
        </DisplayModal>;
    }
}

export default CustomerEvents;
