import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import InputTextArea from '../../general/InputTextArea';
import ButtonFetch from '../../fetch/ButtonFetch';
import { createFetchNotesAdd } from '../../../backend/apiCalls';
import generalFailedPC from '../../fetch/generalFailedPC';

/**
 * @dusan
 */

class AddNote extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number.isRequired,
        parentNoteId: PropTypes.number,
        onFinish: PropTypes.func.isRequired,
        onCancel: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            message: undefined,
        };
    }

    onMessageChange = (newMsg) => {
        this.setState({message: newMsg});
    }
    
    onFinished = () => {
        const {onFinish} = this.props;
        this.setState({message: undefined});
        onFinish();
    }

    render() {
        const {customerId, parentNoteId, onCancel} = this.props;
        const {message} = this.state;
        return <div className="d-flex full-size-width align-items-end">
            <InputTextArea
                value={message}
                onChange={this.onMessageChange}
                changeImmediately={true}
                autoSize={{
                    minRows: parentNoteId ? 3 : 2,
                    maxRows: 10
                }}
                placeholder={parentNoteId ? t`Zadajte text odpovede...` : t`Zadajte text poznámky...`}
            />
            <div className="px-2">
                {parentNoteId != null && onCancel != null ? 
                    <div className="link-button p-2" onClick={onCancel}>
                        <Trans>Zrušiť</Trans>
                    </div> : null
                }
                <ButtonFetch
                    type="primary"
                    icon="plus"
                    size="large"
                    disabled={message == null || message.trim() == ''}
                    values={{}}
                    onFinish={this.onFinished}
                    Failed={generalFailedPC(t`Pridanie poznámky zlyhalo`)}
                    fetchFunction={createFetchNotesAdd({
                        id_customer: customerId,
                        id_parent: parentNoteId,
                        message: message,
                    })}
                />
            </div>
       </div>
    }

}

export default AddNote;