import React from 'react';
import Helmet from 'react-helmet'
import PropTypes from 'prop-types';
import {t} from '@lingui/macro';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { RIGHTS } from '../../constants/Rights';

/**
 * @fero
 */

class ProjectHelmet extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node,
        description: PropTypes.node,
        meta: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                description: PropTypes.string,
            })
        ),
    };

    render() {
        const {title, description, meta = [], 
            [SESSION_ATTRIBUTES.SETTINGS]: settings, 
            [SESSION_ATTRIBUTES.RIGHTS]: rights,
            [SESSION_ATTRIBUTES.SETUP_GTAG]: setupGtag,  
            children} = this.props;
        const useSmartarget =  rights < RIGHTS.WORKER && settings.smartarget_code != null;
        const useGtag = rights < RIGHTS.WORKER && settings.google_analytics_code != null;

        return <Helmet
            title={`${title ? title : t`E-shop`} | ${settings.company_name || ''}`}
            meta={[
                {
                    name: 'description',
                    content: description || settings.default_meta_description || ''
                },
                ...meta
            ]}
        >
            {children}
            { useGtag ?
                <script>{setupGtag()}</script>
                :
                null
            }
            { useGtag ?
                <script async src={"https://www.googletagmanager.com/gtag/js?id=" + settings.google_analytics_code}></script> 
                :
                null
            }
            { useSmartarget ?
                <script async src={"https://smartarget.online/loader.js?u=" + settings.smartarget_code}></script> 
                :
                null
            }
        </Helmet>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS, SESSION_ATTRIBUTES.SETTINGS, SESSION_ATTRIBUTES.SETUP_GTAG])(ProjectHelmet);