import React from 'react';
import PropTypes from 'prop-types'
import {Trans, t} from '@lingui/macro'
import InputTextArea from '../../general/InputTextArea';
import { createFetchNotesEdit, createFetchNotesRemove } from '../../../backend/apiCalls';
import { CustomerNote, UserInfo } from '../../../constants/propTypesDefinitions';
import { GLOBAL_DATA } from '../../../constants/globalData';
import withDataHOC from '../../dataProvider/withDataHOC';
import { Button, Icon } from 'antd';
import DateTime from '../../general/DateTime';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import AddNote from './AddNote';
import { stopPropagation } from '../../../lib/interaction';


/**
 * @dusan
 */

class NotesTree extends React.PureComponent {
    static propTypes = {
        notes: PropTypes.arrayOf(CustomerNote.isRequired),
        reload: PropTypes.func.isRequired,
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedNoteId: undefined,
        };
    }

    onSelectionChange = (noteId) => {
        this.setState({selectedNoteId: noteId});
    }
    
    render() {
        const {notes, [GLOBAL_DATA.ACCOUNT_INFO]: currentUser, reload} = this.props;
        const {selectedNoteId} = this.state;
        const currentUserId= currentUser != null ? currentUser.id : null;
        if(notes == null || notes.length == 0)
            return <h4 className="p-3"><Trans>Žiadne poznámky</Trans></h4>;
        
        return <div className="full-size-width">
            { notes.map(note => {
                return <NotesTreeNodeWrapped
                    key={note.id}
                    note={note}
                    currentUserId={currentUserId}
                    reload={reload}
                    selectedNoteId={selectedNoteId}
                    onSelect={this.onSelectionChange}
                />
            })}
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.ACCOUNT_INFO])(NotesTree);

class NotesTreeNode extends React.PureComponent {
    static propTypes = {
        note: CustomerNote.isRequired,
        currentUserId: PropTypes.number.isRequired,
        selectedNoteId: PropTypes.number,
        onSelect: PropTypes.func.isRequired,
        reload: PropTypes.func.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            newReply: false,
            message: undefined,
        };
    }

    onChange = (newMsg) => {
        this.setState({message: newMsg});
    }

    onMsgKeyDown = (ev) => {
        if(ev.keyCode === 13 && ev.ctrlKey)
            this.onSave();
    }

    onSave = () => {
        const {reload, note} = this.props;
        const {message} = this.state;
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchNotesEdit(),
            {
                id: note.id,
                message: message,
            },
            () => {
                this.setState({
                    isOpened: false,
                    message: undefined
                });
                reload();
            }
        );
    }

    onOpen = () => {
        const {note} = this.props;
        this.setState({
            isOpened: true,
            message: note.message,
        });
    }

    onClose = () => {
        this.setState({
            isOpened: false,
            message: undefined,
        });
    }

    onReply = () => {
        this.setState({newReply: true});
    }

    onCancelReply = () => {
        this.setState({newReply: false});
    }

    info = (note) => {
        return <div>
            <DateTime timeString={note.created_at}/>
            {' '}
            <span className="font-weight-bold">{note.dealer}</span>
            { note.edited_at != null ? 
                <span className="pl-2 font-italic">
                    {'('}
                    <Trans>upravené:</Trans>
                    {' '}
                    <DateTime timeString={note.edited_at}/>
                    {')'}
                </span> :
                null
            }
        </div>;
    }

    responses = (note) => {
        const {currentUserId, reload, selectedNoteId, onSelect} = this.props;
        const {newReply} = this.state;
        return <div className="full-size-width">
            { note.children != null ? note.children.map(obj => {
                return <div className="d-flex full-size-width align-items-start" key={obj.id}>
                    <Icon type="caret-right" className="py-3 px-2"/>
                    <NotesTreeNodeWrapped
                        note={obj}
                        currentUserId={currentUserId}
                        reload={reload}
                        selectedNoteId={selectedNoteId}
                        onSelect={onSelect}
                    />
                </div>;
            }) : null}
            { newReply ? <div className="d-flex full-size-width align-items start">
                    <Icon type="caret-right" className="py-3 px-2"/>
                    <AddNote
                        customerId={note.id_customer}
                        parentNoteId={note.id}
                        onFinish={() => {
                            this.setState({newReply: false});
                            reload();
                        }}
                        onCancel={this.onCancelReply}
                    />
                </div> : null
            }
        </div>;
    }

    onClick = (ev) => {
        const {note, selectedNoteId, onSelect} = this.props;
        if(note != null && selectedNoteId != note.id)
            onSelect(note.id);
        else
            onSelect(null);
    }

    onMouseMove = (ev) => {
        const {note, onSelect, selectedNoteId} = this.props;
        stopPropagation(ev);
        if(note != null && selectedNoteId != note.id)
            onSelect(note.id);
    }

    onMouseLeave = (ev) => {
        const {note, selectedNoteId, onSelect} = this.props;
        stopPropagation(ev);
        if(note != null && selectedNoteId == note.id)
            onSelect(null);
    }

    render() {
        const {note, currentUserId, selectedNoteId, reload} = this.props;
        const {isOpened, message, newReply} = this.state;
        const noteChecked = note != null ? note : {};
        const isSelected = (selectedNoteId != null && noteChecked.id == selectedNoteId);
        const isEditable = (noteChecked.id_dealer == currentUserId);
        if(isOpened && isEditable) {
            return <div className="py-2 full-size-width">
                <div className="customer-notes-info">
                    {this.info(noteChecked)}
                </div>
                <InputTextArea
                    value={message}
                    onChange={this.onChange}
                    changeImmediately={true}
                    onKeyDown={this.onMsgKeyDown}
                />
                <div className="d-flex justify-content-end">
                    <Button
                        size="small"
                        className="m-1"
                        onClick={this.onClose}
                    >
                        <Trans>Zrušiť</Trans>
                    </Button>
                    <Button
                        size="small"
                        type="primary"
                        className="m-1"
                        onClick={this.onSave}
                        disabled={message == null || message.trim() == ''}
                    >
                        <Trans>Uložiť</Trans>
                    </Button>
                </div>
                {this.responses(noteChecked)}
            </div>;
        }
        else {
            return <div 
                className={"py-2 full-size-width customer-notes" + (isSelected ? " customer-notes-selected" : "")}
                onClick={this.onClick}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
            >
                <div className="d-flex customer-notes-info">
                    {this.info(noteChecked)}
                    { isSelected && isEditable ? 
                        <div className="link-button px-2" onClick={this.onOpen}>
                            <Trans>Upraviť</Trans>
                        </div> : null
                    }
                    { isSelected && isEditable && (noteChecked.children == null || noteChecked.children.length == 0) ?
                        <RemoveNote
                            noteId={noteChecked.id}
                            onFinish={reload}
                        /> : null
                    }
                    { isSelected && newReply != true ? 
                        <div className="link-button px-2 color-primary font-weight-bold" onClick={this.onReply}>
                            <Trans>Odpovedať</Trans>
                        </div> : null
                    }
                </div>
                <div className="full-size-width">
                    <pre>{noteChecked.message}</pre>
                </div>
                {this.responses(noteChecked)}
            </div>;
        }
    }
}

const NotesTreeNodeWrapped = withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(NotesTreeNode);

class RemoveNote extends React.PureComponent {
    static propTypes = {
        noteId: PropTypes.number.isRequired,
        onFinish: PropTypes.func.isRequired,
    };

    render() {
        const {noteId, onFinish} = this.props;
        return <FormModal
            openNode={
                <div className="link-button color-red">
                    <Trans>Vymazať</Trans>
                </div>
            }
            values={{}}
            onFinishSuccessful={onFinish}
            title={<Trans>Vymazanie poznámky</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete vymazať poznámku?</Trans>,
                createFetchNotesRemove(noteId),
            )}
            Failed={generalFailedPC(t`Nepodarilo sa vymazať poznámku.`)}
        />
    }
}