import React from 'react';
import Link from '../navigation/Link';
import withDataHOC from '../dataProvider/withDataHOC';
import {Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../constants/globalData';
import {ROUTES, QUERY_PARAMS} from '../../constants/navigation';
import Price from '../general/Price';

/**
 * @fero
 */

class CartButton extends React.PureComponent {

    render() {
        const cartCurrent = this.props[GLOBAL_DATA.CART_CURRENT];
        const linesCount = cartCurrent != null ? (cartCurrent.lines_count != null ? cartCurrent.lines_count : 0) : 0;
        const sumNetto = cartCurrent != null ? (cartCurrent.sum_netto != null ? cartCurrent.sum_netto : null) : null;
        const sumBrutto = cartCurrent != null ? (cartCurrent.sum_brutto != null ? cartCurrent.sum_brutto : null) : null;
        const noItems = linesCount == 0;

        return <div className="d-flex">
            <Link
                to={ROUTES.CART}
                queryParams={{[QUERY_PARAMS.CART_STEP]: 0}}
                className="m-2 p-2 ant-btn normal-line-height ant-btn-primary cart-button"
            >
                <div className="d-flex align-items-center">
                    <Icon className="pr-3" type="shopping-cart" style={{fontSize: '1.5rem'}}/>
                    { linesCount > 0 ? 
                        <div>
                            <div><Trans>Počet položiek:</Trans>{' '}{linesCount}</div>
                            <div>
                                <Trans>Spolu bez DPH:</Trans>
                                {' '}
                                {!noItems ? 
                                    <Price 
                                        price={sumNetto} 
                                        currency={cartCurrent.id_currency}
                                        nullOption={<Trans>na dopyt</Trans>}
                                    /> 
                                    : '-'
                                }
                            </div>
                            <div className="font-weight-bold">
                                <Trans>Spolu s DPH:</Trans>
                                {' '}
                                {!noItems ? 
                                    <Price 
                                        price={sumBrutto}
                                        currency={cartCurrent.id_currency}
                                        nullOption={<Trans>na dopyt</Trans>}
                                    /> 
                                    : '-'
                                }
                            </div>
                        </div> : 
                        <div>
                            <div><Trans>Košík</Trans></div>
                            <div><Trans>je prázdny</Trans></div>
                        </div>
                    }
                </div>
            </Link>
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.CART_CURRENT])(CartButton);