import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import { formatDateTime } from '../../lib/date';

/**
 * @fero
 */

class DateTime extends React.PureComponent {
    static propTypes = {
        timeString: PropTypes.string,
        nullOption: PropTypes.node,
    };

    render() {
        const {timeString, nullOption} = this.props;
        return (timeString != null ? formatDateTime(timeString) 
          : (nullOption != null ? nullOption : <Trans>neuvedené</Trans>));
    }

}

export default DateTime;