import PropTypes from 'prop-types';
import React from 'react';
import InputNumber from '../general/InputNumber';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { t } from '@lingui/macro';

/**
 * @dusan
 */

class InputPrice extends React.Component {
    static propTypes = {
        size: PropTypes.oneOf(['small', 'large']),
        wrapperClassName: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        currency: PropTypes.string,
        withVAT: PropTypes.bool,
    };

    render() {
        const {size, wrapperClassName, onChange, value, currency, withVAT, 
            [SESSION_ATTRIBUTES.SETTINGS]: settings, ...props} = this.props;
        return <InputNumber
            {...props}
            size={size}
            wrapperClassName={wrapperClassName}
            value={value}
            onChange={onChange}
            after={
                <span className="ml-1 text-nowrap">
                    {(currency != null ? currency : settings.default_currency) + (withVAT != null ?
                        " " + (withVAT ? t`s DPH` : t`bez DPH`)
                        : ""
                    )}
                </span>
            }
        />;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(InputPrice);