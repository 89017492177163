import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../general/Tooltip';
import {ASYNC_STATE} from '../../constants/AsyncState';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Icon, Input, Popconfirm} from 'antd';
import {createUserLogin, createUserForgottenPassword, createUserSendLoginToken} from '../../backend/apiCalls';
const FormItem = Form.Item;

/**
 * @fero
 */

class LoginForm extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingReset: false,
            serverError: null,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.setState({
            serverError: null,
        });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({loading: true});
                createUserLogin()(values.e_mail, values.password).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    if (response.status < 400 && response.asyncState == ASYNC_STATE.SUCCEEDED) 
                    {
                        onSubmitFinish(response);
                    } 
                    else if (
                        response.status == 400 &&
                        response.result != null &&
                        response.result.code != null &&
                        (response.result.code == 6 || response.result.code == 8 || response.result.code == 32)
                    ) 
                    {
                        if (response.result.code == 6) 
                        {
                            this.props.form.setFields({
                                password: {
                                    value: values.password,
                                    errors: [new Error('Nesprávne heslo.')],
                                },
                            });
                        }
                        else if (response.result.code == 8) 
                        {
                            this.props.form.setFields({
                                e_mail: {
                                    value: values.e_mail,
                                    errors: [new Error('Nesprávny email.')],
                                },
                            });
                        }
                        else if (response.result.code == 32) 
                        {
                            this.props.form.setFields({
                                password: {
                                    value: values.password,
                                    errors: [new Error('Chýba email, alebo heslo.')],
                                },
                            });
                        }
                    }
                    else if (response.asyncState == ASYNC_STATE.EXCEPTION) 
                    {
                        this.setState({serverError: response.result});
                    } 
                    else 
                    {
                        this.setState({serverError: response.result.message});
                    }
                });
            }
        });
    };

    handleForgottenPassword = (e) => {
        e.preventDefault();
        this.setState({loadingReset: true});
        this.props.form.validateFields((err, values) => {
            this.setState({loadingReset: false});
            createUserForgottenPassword()(values.e_mail).then((response) => {
                if (response.status < 400) {
                    //TODO handle response(probably set "like servetError" so it displays that it has been send(but no red :D))
                } else {
                    this.setState({
                        serverError: response.result.message,
                    })
                }
            })
        });
    };

    handleTokenLogin = (e) => {
        e.preventDefault();
        this.setState({loadingReset: true});
        this.props.form.validateFields((err, values) => {
            this.setState({loadingReset: false});
            createUserSendLoginToken()(values.e_mail).then((response) => {
                if (response.status < 400) {
                    //TODO handle response(probably set "like servetError" so it displays that it has been send(but no red :D))
                } else {
                    this.setState({
                        serverError: response.result.message,
                    })
                }
            })
        });
    };

    render() {
        const {loading, loadingReset, serverError} = this.state;
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        const hasMail = this.props.form.getFieldValue('e_mail') != null && this.props.form.getFieldValue('e_mail') != '';
        return (
            <Form onSubmit={this.handleSubmit} className="">
                <FormItem>
                    {getFieldDecorator('e_mail', {
                        rules: [
                            {required: true, message: 'Povinné'}
                        ],
                    })(
                        <Input ref={node => this.first = node}
                               prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="email"/>
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {})(
                        <Input prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                               placeholder="heslo"/>
                    )}
                </FormItem>
                <FormItem>
                    <div className="d-flex flex-column">
                        <div>
                            <Button onClick={onClose}>
                                <Trans>Zrušiť</Trans>
                            </Button>
                            <Button loading={loading} type="primary" htmlType="submit">
                                <Trans>Prihlásiť</Trans>
                            </Button>
                        </div>
                    </div>
                </FormItem>
                <Tooltip title={hasMail ? null : <Trans>Zadajte email, prosím.</Trans>}>
                    <Popconfirm
                        placement="top"
                        title={<Trans>Prajete si odoslať odkaz pre zmenu hesla na zadaný email?</Trans>}
                        onConfirm={this.handleForgottenPassword}
                        okText={<Trans>Áno</Trans>}
                        cancelText={<Trans>Nie</Trans>}
                    >
                        <Button disabled={!hasMail} loading={loadingReset}>
                            <Trans>Zabudli ste heslo?</Trans>
                        </Button>
                    </Popconfirm>
                </Tooltip>
                <Tooltip title={hasMail ? null : <Trans>Zadajte email, prosím.</Trans>}>
                    <Popconfirm
                        placement="top"
                        title={<Trans>Prajete si odoslať odkaz na jednorazové prihlásenie na zadaný email?</Trans>}
                        onConfirm={this.handleTokenLogin}
                        okText={<Trans>Áno</Trans>}
                        cancelText={<Trans>Nie</Trans>}
                    >
                        <Button disabled={!hasMail} loading={loadingReset}>
                            <Trans>Jednorazové prihlásenie pomocou e-mailu</Trans>
                        </Button>
                    </Popconfirm>
                </Tooltip>
                {serverError != null ? <div className="text-danger p-5">
                    <h5>
                        <Trans>Pri komunikácíí so serverom došlo k chybe.</Trans>
                    </h5>
                    <div>
                        {serverError}
                    </div>
                </div> : null}
            </Form>
        );
    }
}

export default Form.create()(LoginForm);