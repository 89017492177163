// @flow
import React from 'react';
/**
 * @fero
 */
const color = "#554433";

class Spinner extends React.PureComponent {
    render() {
        return <div className="full-size d-flex justify-content-center align-items-center">
            <div className="full-size spinner-size-max">
                <svg className="full-size"
                     viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid">
                    <g>
                        <path d="M50 15A35 35 0 1 0 74.787 25.213" fill="none" stroke={color} strokeWidth="12px"/>
                        <path d="M50 0L50 30L66 15L50 0" fill={color}/>
                        <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="1s"
                                          repeatCount="indefinite">
                        </animateTransform>
                    </g>
                </svg>
            </div>
        </div>
    }
}

export default Spinner;
