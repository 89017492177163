import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class InfoAttribute extends React.PureComponent {
    static propTypes = {
        attributeTitle: PropTypes.node,
        attributeValue: PropTypes.node,
        isRequired: PropTypes.bool,
        hasError: PropTypes.bool,
        nullLabel: PropTypes.node,
    };

    static defaultProps = {
        nullLabel: <Trans>neuvedené</Trans>
    };

    render() {
        const {attributeValue, attributeTitle, isRequired, hasError, nullLabel} = this.props;
        return <div className="p-2 info-attribute">
            { attributeTitle != null ?
                <div className={"text-secondary info-attribute-title" + (isRequired ? " ant-form-item-required" : "")}>
                    {attributeTitle}
                </div> :
                null
            }
            <div className={(attributeTitle != null ? "info-attribute-value" : "full-size-width") + (hasError ? " has-error" : "")}>
                {attributeValue != null ? attributeValue : nullLabel}
            </div>
        </div>;
    }

}

export default InfoAttribute;