/**
 * @author fero
 */

/*
* Merges 2 objects. Removes parameters that have value undefined.
*
*/
export function merge(...toMerge) {
    var result = {};
    for (var i = 0; i < toMerge.length; i++) {
        if(toMerge[i]) {
            Object.assign(result, toMerge[i]);
        }
    }
    return result;
}

/*
* Sets defaults (coalesce operator over objects).
*
*/
export function setDefaultAttributes(obj, defaultObj) {
    var result = obj;
    if(defaultObj == null)
        return result;
        
    for (var propName in defaultObj) {
        if(obj[propName] == null) {
            result[propName] = defaultObj[propName];
        }
    }
    return result;
}

/*
* Returns first node of tree which id corresponds to given id or null if not found. Node's children must be under attribute
* children, otherwise search consider there are no children.
*/
export function searchTreeById(node, id) {
    if(node.id == id) 
    {
        return node;
    } 
    else if(node.children != null) 
    {
        for(let i=0; i < node.children.length; i++)
        {
            const result = searchTreeById(node.children[i], id);
            if(result != null)
                return result;
        }
    } 

    return null;
}

export function getTreePath(node, id) {
    if(node.id == id)
    {
        return [id];
    }
    else if(node.children != null) 
    {
        for(let i=0; i < node.children.length; i++)
        {
            const result = getTreePath(node.children[i], id);
            if(result != null)
                return [node.id, ...result];
        }
    } 
    
    return null;
}

/*
* Sets attributes that have null value to undefined.
* Manipuletes directly with object, it's not creating a new one.
*/
export function nullToUndefined(obj) {
    for (var propName in obj) {
        if(obj.hasOwnProperty(propName) && obj[propName] === null) {
            obj[propName] = undefined;
        }
    }
}


/*
 * Removes attributes that have undefined or null as theirs value.
 * Manipuletes directly with object, it's not creating a new one.
 */
export function removeNullAttributes(obj) {
    for (var propName in obj) {
        if(obj.hasOwnProperty(propName) && obj[propName] == null) {
            delete obj[propName];
        }
    }
}
/*
 * Removes attributes that have undefined as theirs value.
 * Manipuletes directly with object, it's not creating a new one.
 */
export function removeUndefinedAttributes(obj) {
    for (var propName in obj) {
        if(obj.hasOwnProperty(propName) && obj[propName] === undefined) {
            delete obj[propName];
        }
    }
}

export function isObject(obj) {
    return (typeof obj === 'object' && obj != null);
}