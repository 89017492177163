import { merge } from './object';
import {getDomain, addQueryParamsToUrl} from './url';
import 'whatwg-fetch';
import { ASYNC_STATE } from '../constants/AsyncState';
import { API_ROOT } from '../constants/apiEndpoints';
/**
 * @fero
 */
export const DEFAULT_OPTIONS = {
    credentials: 'include',
};

export const POST_OPTIONS = {
    method: 'POST'
};

export const GET_OPTIONS = {
    method: 'GET'
};


export function fetchJson(target, relativeUrl, requestOptions) {
    const requestOptionsWithDefaults = merge(DEFAULT_OPTIONS, requestOptions);
    const domain = getDomain() + (process.env.API_PORT != null && process.env.API_PORT != 80 ? ':' + process.env.API_PORT : '');
    return fetch(domain+target+relativeUrl, requestOptionsWithDefaults).then((response) => {
        return response.json().then((json) => {
            if(json.error != null)
            {
                return {
                    asyncState: ASYNC_STATE.FAILED,
                    status: response.status,
                    result: json.error,
                };
            }
            else
            {
                return {
                    asyncState: ASYNC_STATE.SUCCEEDED,
                    status: response.status,
                    result: json.result,
                };
            }
        });
    }).catch((ex) => {
        return {
            asyncState: ASYNC_STATE.EXCEPTION,
            status: null,
            result: ex.toString(),
        }
    });
}

export function fetchApiJson(relativeUrl, requestOptions) {
    return fetchJson(API_ROOT, relativeUrl, requestOptions);
}

export function fetchPostJson(url, queryParams, bodyObject, requestOptions) {
    const urlWithQueryParams = addQueryParamsToUrl(url, queryParams);
    const requestOptionsPost = merge(requestOptions, POST_OPTIONS);
    const bodyOption = {body: JSON.stringify(bodyObject)};
    const requestOptionsWithBody = bodyObject != null ? merge(requestOptionsPost, bodyOption) : requestOptionsPost;
    return fetchApiJson(urlWithQueryParams, requestOptionsWithBody);
}

export function fetchGetJson(url, queryParams) {
    const urlWithQueryParams = addQueryParamsToUrl(url, queryParams);
    return fetchApiJson(urlWithQueryParams, GET_OPTIONS);
}

