import PropTypes from 'prop-types';
import React from 'react';
import InputNumber from '../general/InputNumber';
import { isNumber } from '../../lib/number';

/**
 * @dusan
 */

class InputPercent extends React.Component {
    static propTypes = {
        size: PropTypes.oneOf(['small', 'large']),
        wrapperClassName: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    onChanged = (newVal) => {
        const {onChange} = this.props;
        const val = isNumber(newVal) ? Number(newVal) / 100.0 : null;
        if(onChange != null)
            onChange(val);
    }

    render() {
        const {size, wrapperClassName, onChange, value, ...props} = this.props;
        const perc = isNumber(value) ? Number(value) * 100.0 : null;
        return <InputNumber
            {...props}
            size={size}
            wrapperClassName={wrapperClassName}
            value={perc}
            onChange={this.onChanged}
            after={<span className="ml-1">%</span>}
        />;
    }

}

export default InputPercent;