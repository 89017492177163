import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button} from 'antd';
import {ButtonSize} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import EventForm from './EventForm';

/**
 * @dusan
 */


class EventAdd extends React.PureComponent {
    static propTypes = {
        wrapperClassName: PropTypes.string,
        type: PropTypes.string,
        size: ButtonSize,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number,
    };

    static defaultProps = {
        wrapperClassName: '',
        type: 'primary',
    };

    render() {
        const {wrapperClassName, type, size, reload, customerId} = this.props;
        return <FormModal
            className={wrapperClassName}
            openNode={
                <Button type={type} size={size}>
                    <Trans>Pridať event</Trans>
                </Button>
            }
            values={{customerId}}
            onFinishSuccessful={reload}
            title={<Trans>Nový event</Trans>}
            Form={EventForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vytvoriť event.`)}
        />;
    }

}

export default EventAdd;