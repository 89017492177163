// @flow
import React from 'react';
import {SessionContext} from './SessionProvider';

/**
 * @fero
 */

const withSessionHOC = (params) => (Component) => {
    return class extends React.Component {
        render() {
            return <SessionContext.Consumer>
                { context => {
                    if(params != null) 
                    {
                        const strippedContext = {};
                        if(Array.isArray(params)) 
                        {
                            for(let i = 0; i < params.length; i++) 
                            {
                                const currentParam = params[i];
                                strippedContext[currentParam] = context[currentParam];
                            }
                        } 
                        else 
                        {
                            console.warn('Params for session context was ', params)
                        }
                        return <Component  {...strippedContext} {...this.props}/>
                    }
                    else 
                    {
                        return <Component  {...context} {...this.props}/>
                    }
                }}
            </SessionContext.Consumer>;
        }
    }
};

export default withSessionHOC;