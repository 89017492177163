import queryString from 'query-string';
import {navigate, withPrefix} from 'gatsby';
import {merge, nullToUndefined, setDefaultAttributes} from './object';
import {ON_CHANGE_PATH_PARAMS, ROUTE_QUERY_DEFAULTS} from '../constants/navigation';
import {PDF_TARGET} from '../constants/apiEndpoints';

/**
 * @fero
 */

const queryStringOpts = {arrayFormat: 'bracket'};

export function changeUrlQueryParams(currentParamsString, newParams) {
    nullToUndefined(newParams);
    const currentParamsObject = queryString.parse(currentParamsString, queryStringOpts);
    const updatedParamsObject = merge(currentParamsObject, newParams);
    return queryString.stringify(updatedParamsObject, queryStringOpts);
}

export function setDefaultUrlQueryParams(currentParamsString, defaultParams) {
    const currentParamsObject = queryString.parse(currentParamsString, queryStringOpts);
    const updatedParamsObject = setDefaultAttributes(currentParamsObject, defaultParams);
    return queryString.stringify(updatedParamsObject, queryStringOpts);
}

export function addQueryParamsToUrl(url, queryParams) {
    const queryParamsStringified = queryString.stringify(queryParams, queryStringOpts);
    return queryParamsStringified.length > 0 ? url + '?' + queryParamsStringified : url;
}

export function relativeUrlWithQueryParams(location, path, newQueryParams, ignoreChangePathParams, ignorePathDefaults) {
    //TODO add navigation to error page when there is no location
    let paramsString = location.search;
    if (path != null && ignoreChangePathParams != true) {
        paramsString = changeUrlQueryParams(paramsString, ON_CHANGE_PATH_PARAMS);
    }
    if (path != null && ignorePathDefaults != true) {
        paramsString = setDefaultUrlQueryParams(paramsString, ROUTE_QUERY_DEFAULTS[path]);
    }
    paramsString = newQueryParams != null ?
        changeUrlQueryParams(paramsString, newQueryParams) :
        "";
    const url = path != null ? path : location.pathname;
    return paramsString.length > 0 ? url + '?' + paramsString : url;
}

export function fullUrlWithQueryParams(location, path, newQueryParams, ignoreChangePathParams, ignorePathDefaults) {
    const relativeUrl = relativeUrlWithQueryParams(
        location, path, newQueryParams, ignoreChangePathParams, ignorePathDefaults
    );
    return withPrefix(relativeUrl);
}

export function navigateToParametrized(location, path, newQueryParams, ignoreChangePathParams, ignorePathDefaults) {
    const urlWithParams = fullUrlWithQueryParams(location, path, newQueryParams, ignoreChangePathParams, ignorePathDefaults);
    navigate(urlWithParams);
}

export const createSetQueryParamValue = (location, queryParam) => (value) => {
    navigateToParametrized(location, null, {[queryParam]: value})
};

export function getQueryUndefinedParamsObject(names) {
    let result = {};
    for (let i = 0; i < names.length; i++) {
        result[names[i]] = undefined
    }
    return result;
}

export function getQueryParamsObject(location, names) {
    let result = {};
    for (let i = 0; i < names.length; i++) {
        result[names[i]] = getQueryParam(location, names[i])
    }
    return result;
}

export function getQueryParam(location, name) {
    //TODO add navigation to error page when there is no location
    const queryParams = queryString.parse(location.search, queryStringOpts);
    return queryParams[name];
}

//return query param as number if possible otherwise return plain(string)
export function getQueryParamNumber(location, name) {
    const queryParam = getQueryParam(location, name);
    return queryParam == null || Number.isNaN(queryParam) ? queryParam : Number(queryParam);

}

export function getRelativePath(relativePathWithPrefix) {
    const relativePath = relativePathWithPrefix;
    if (process.env.PREFIX_PATHS) 
    {
        const startIndexOfPrefix = relativePath.indexOf(process.env.PATH_PREFIX);
        const lengthOfPrefix = process.env.PATH_PREFIX.length;
        const endIndexOfPrefix = startIndexOfPrefix + lengthOfPrefix;
        return relativePath.substring(endIndexOfPrefix);
    } 
    else 
    {
        return relativePath;
    }
}

export function getFullPath(relativePath) {
    return getDomainWithPort() + withPrefix(relativePath);
}

//returns domain without port
export function getDomain() {
    return window.location.protocol + '//' + window.location.hostname;
}

//returns domain with port(only if it's present)
export function getDomainWithPort() {
    return window.location.protocol + '//' + window.location.host;
}

export function hasPathChanged(prevLocation, location) {
    return location.path !== prevLocation.path;
}

export function downloadPdf(type, id) {
    return `${getDomain()}${PDF_TARGET}?type=${type}&id=${id}`
}