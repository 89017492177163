import React from 'react';
import PropTypes from 'prop-types';
import Failed from './Failed';

/**
 * Class that serves for failure after fetch if no special message is needed.
 * @fero
 */

const generalFailedPC = (message) => {
    return class extends React.PureComponent {
        static propTypes = {
            result: PropTypes.object.isRequired,
        };

        render() {
            const {result} = this.props;
            const checkedDetails = result != null && result.message != null ? result.message : result;

            return <Failed
                message={message}
                errorMessage={JSON.stringify(checkedDetails)}
            />;
        }

    }
};

export default generalFailedPC;