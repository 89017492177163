import React from 'react';
import {Button, Icon, Menu } from 'antd';
import PropTypes from 'prop-types';
import Tooltip from '../../general/Tooltip';
import {createFetchFinstatDetails} from '../../../backend/apiCalls';
import {Customer} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { formatPrice, formatString } from '../../../lib/formatting';
import InfoAttributeGroup from '../../general/InfoAttributeGroup';
import Date from '../../general/Date';
import { formatDateTime } from '../../../lib/date';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import withDataHOC from '../../dataProvider/withDataHOC';
import DisplayModal from '../../general/DisplayModal';
import Spinner from '../../graphics/Spinner';

/**
 * @dusan
 */

class Finstat extends React.PureComponent {
    static propTypes = {
        customer: Customer.isRequired,
        type: PropTypes.oneOf(['button', 'menu-item']),
    };

    static defaultProps = {
        type: 'button'
    };

    isValid = () => {
        const { customer = {}, [SESSION_ATTRIBUTES.SETTINGS]: settings} = this.props;
        if(settings.finstat_access == null || settings.finstat_access == '')
            return false;

        if(customer.ico == null || customer.ico == "")
            return false;

        if(customer.ic_dph != null && customer.ic_dph.substring(0, 2) != 'SK')
            return false;

        return true;
    }

    render() {
        const {customer, type, [SESSION_ATTRIBUTES.SETTINGS]: settings, ...props} = this.props;

        if(!this.isValid())
            return null;
        
        const openNode = type == 'menu-item' ? 
            <Menu.Item {...props}>
                <div 
                    className="full-size-width" 
                >
                    <Icon type="line-chart" className="pr-2"/>
                    <Trans>FinStat</Trans>
                </div>
            </Menu.Item>
            :
            <Tooltip title={<Trans>Zobraziť aktuálny FinStat záznam</Trans>}>
                <Button
                    {...props}
                >
                    <Icon type="line-chart" style={{fontSize: "120%"}} className="pr-2"/>
                    <Trans>FinStat</Trans>
                </Button>
            </Tooltip>; 
            
        return <DisplayModal
            modalClassName="small-modal"
            openNode={openNode}
            title={t`FinStat záznam pre IČO ${customer.ico}`}
        >
            <FinstatInfoWrapped
                ico={customer.ico}
            />
        </DisplayModal>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(Finstat);

class FinstatInfo extends React.PureComponent {
    static propTypes = {
        ico: PropTypes.string,
        [GLOBAL_DATA.FETCH_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            info: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const {ico} = this.props;
        const {ico: prevIco} = prevProps;
        if(ico != prevIco)
            this.fetchData();
    }

    fetchData = () => {
        const {[GLOBAL_DATA.FETCH_DATA]: fetchHandler, ico} = this.props;

        if(ico == null)
        {
            this.setState({
                info: {},
                loading: false
            });
            return;
        }

        this.setState({loading: true});
        fetchHandler(
            createFetchFinstatDetails(),
            {ico: ico},
            (result) => {
                this.setState({
                    info: result != null ? result : {},
                    loading: false
                });
            }
        )
    };

    upDownArrow = (str) => {
        if(str == 'Up')
            return <Icon className='ml-2 color-green' type="caret-up"/>;
        else if(str == 'Down' || str == 'Loss')
            return <Icon className='ml-2 color-red' type="caret-down"/>;
        else
            return null;
    }

    render() {
        const {info, loading} = this.state;
        if(loading)
            return <Spinner/>;

        const bankAccounts = info.BankAccounts != null ? info.BankAccounts.BankAccount : null;
        const ibans = bankAccounts != null ? 
            ( Array.isArray(bankAccounts) ? bankAccounts : [bankAccounts] )
            :
            []
        ;

        let warnings = [];

        if(info.PaymentOrderWarning == 'true')
        {
            warnings.push(
                <a href={info.PaymentOrderUrl}><Trans>Platobné rozkazy</Trans></a>
            );
        }

        if(info.HasKaR == 'true')
        {
            warnings.push(
                <a href={info.KaRUrl}><Trans>Konkurzy</Trans></a>
            );
        }

        if(info.HasDebt == 'true')
        {
            warnings.push(
                <a href={info.DebtUrl}><Trans>Dlhy a pohľadávky</Trans></a>
            );
        }

        return <InfoAttributeGroup
            attributes={[
                {
                    title: <Trans>Názov:</Trans>,
                    value: <div>
                        <h3>{info.Name}</h3>
                        { info.Warning == "true" ? 
                            <Icon type="warning" theme="twoTone" twoToneColor="#ffbf00"/>
                            :
                            null
                        }
                    </div>
                },
                {
                    title: <Trans>Celkové výnosy:</Trans>,
                    value: <div>
                        {formatPrice(info.RevenueActual)}
                        {this.upDownArrow(info.Revenue)}
                    </div>
                },
                {
                    title: <Trans>Zisk:</Trans>,
                    value: <div>
                        {formatPrice(info.ProfitActual)}
                        {this.upDownArrow(info.Profit)}
                    </div>
                },
                {
                    title: <Trans>Upozornenia:</Trans>,
                    value: <div className='color-red'>
                        {warnings.map((w, idx) => {
                            return <div key={idx}>{w}</div>
                        })}
                    </div>,
                    isHidden: warnings.length == 0
                },
                {
                    title: <Trans>URL záznamu:</Trans>,
                    value: <a href={info.Url}>finstat.sk</a>
                },
                {
                    title: <Trans>IČO:</Trans>,
                    value: formatString(info.Ico)
                },
                {
                    title: <Trans>IČ DPH:</Trans>,
                    value: formatString(info.IcDPH) + (info.IcDphAdditional != null && info.IcDphAdditional.Paragraph != null ? 
                        ' (' + info.IcDphAdditional.Paragraph + ')' : '')
                },
                {
                    title: <Trans>DIČ:</Trans>,
                    value: formatString(info.Dic)
                },
                {
                    title: <Trans>Sídlo:</Trans>,
                    value: <div>
                        <div>{info.Street + ' ' + info.StreetNumber}</div>
                        <div>{(info.ZipCode != null && info.City != null) ?
                            info.ZipCode + " " + info.City :
                            null
                        }</div>
                    </div>
                },
                {
                    title: <Trans>Kraj:</Trans>,
                    value: formatString(info.Region)
                },
                {
                    title: <Trans>Zameranie:</Trans>,
                    value: formatString(info.Activity)
                },
                {
                    title: <Trans>Dátum vzniku:</Trans>,
                    value: <Date dateString={info.Created}/>
                },
                {
                    title: <Trans>Dátum zániku:</Trans>,
                    value: <span className="color-red">
                        <Date dateString={info.Cancelled}/>
                    </span>,
                    isHidden: info.Cancelled == null
                },
                {
                    title: <Trans>Register:</Trans>,
                    value: formatString(info.RegisterNumberText)
                },
                {
                    title: <Trans>Právna forma:</Trans>,
                    value: formatString(info.LegalFormText)
                },
                {
                    title: <Trans>SK NACE:</Trans>,
                    value: formatString(info.SkNaceCode + ' ' + info.SkNaceText)
                },
                {
                    title: <Trans>Bankové účty:</Trans>,
                    value: <div>
                        { ibans.map((b, key) => {
                            return <div key={key}>
                                <Tooltip title={"od " + formatDateTime(b.PublishedAt)}>
                                    {b.AccountNumber}
                                </Tooltip>
                            </div>
                        })}
                    </div>
                }
            ]}
        />;
    }
};

const FinstatInfoWrapped = withDataHOC([GLOBAL_DATA.FETCH_DATA])(FinstatInfo);
