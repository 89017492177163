import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import {Button} from 'antd';
/**
 * Class for creating forms in modal with no parameters(questions).
 * @fero
 */

const generalNoParameterFormPC = (
    mainText,
    fetchFunc,
) => {
    return class extends React.Component {
        static propTypes = {
            //from FormModal
            onClose: PropTypes.func.isRequired,
            onSubmitFinish: PropTypes.func.isRequired,
            values: PropTypes.shape({}).isRequired,
        };

        constructor(props) {
            super(props);
            this.first = React.createRef();
            this.state = {
                loading: false,
            };
        }

        componentDidMount() {
            const buttonNode = ReactDOM.findDOMNode(this.first.current);
            setTimeout(() => {
                if(buttonNode.focus != null) {
                    buttonNode.focus();
                }
            }, 0);
        }

        handleFetch = (e) => {
            const {onSubmitFinish, values} = this.props;
            e.preventDefault();
            this.setState({loading: true});
            fetchFunc(values).then((response) => {
                this.setState({
                    loading: false,
                });
                onSubmitFinish(response);
            });
        };

        render() {
            const {loading} = this.state;
            const {onClose} = this.props;
            return (
                <div>
                    <h5>{mainText}</h5>
                    <div className="mt-3 d-flex justify-content-end">
                        <Button onClick={onClose}>
                            <Trans>Nie</Trans>
                        </Button>
                        <Button ref={this.first}  onClick={this.handleFetch} loading={loading} type="primary">
                            <Trans>Áno</Trans>
                        </Button>
                    </div>
                </div>
            );
        }
    };
};

export default generalNoParameterFormPC;