import React from 'react';
import Link from '../navigation/Link';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {Icon, Badge} from 'antd';
import {GLOBAL_DATA} from '../../constants/globalData';
import {ROUTES, QUERY_PARAMS} from '../../constants/navigation';

/**
 * @fero
 */

class CartButtonMobile extends React.PureComponent {

    render() {
        const cartCurrent = this.props[GLOBAL_DATA.CART_CURRENT];
        const linesCount = cartCurrent != null ? (cartCurrent.lines_count != null ? cartCurrent.lines_count : 0) : 0;

        return <div className="py-3 pl-3 pr-4">
            <Badge count={linesCount}>
                <Link
                    to={ROUTES.CART}
                    queryParams={{[QUERY_PARAMS.CART_STEP]: 0}}
                    className="cart-button-mobile"
                >
                    <Icon className="" type="shopping-cart" style={{fontSize: '3rem'}}/>
                </Link>
            </Badge>
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.CART_CURRENT])(CartButtonMobile);