import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import withDataHOC from '../dataProvider/withDataHOC';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Employee} from '../../constants/propTypesDefinitions';
/**
 * @fero
 */

class SupervisorsSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.SUPERVISORS]: PropTypes.arrayOf(Employee.isRequired).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        onChange: PropTypes.func,
        className: PropTypes.string,
        allowClear: PropTypes.bool,
        focus: PropTypes.bool,
    };

    render() {
        const {[GLOBAL_DATA.SUPERVISORS]: dealers, value, ...props} = this.props;
        const valueChecked = value != null ? Number(value) : null;
        return <Select
            {...props}
            options={dealers.map(dealer => {
                return {
                    label: dealer.fullname,
                    value: dealer.id,
                }
            })}
            value={valueChecked}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.SUPERVISORS])(SupervisorsSelect);