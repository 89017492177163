import React from 'react';
import {locationHOC} from '../../locationProvider/locationHOC';
import {Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import {navigateToParametrized} from '../../../lib/url';
import {ROUTES} from '../../../constants/navigation';

/**
 * @dusan
 */

class Assignments extends React.PureComponent {

    render() {
        const {location} = this.props;
        return <div className="full-size-width" onClick={() => {navigateToParametrized(location, ROUTES.ASSIGNMENTS, {})}}>
                <Icon type="team" className="pr-2"/>
                <Trans>Úlohy a odmeny</Trans>
            </div>;
    }

}

export default locationHOC(Assignments);