import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import {Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * @fero
 */

class Logout extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.LOGOUT]: PropTypes.func.isRequired,
    };

    render() {
        const {[SESSION_ATTRIBUTES.LOGOUT]: logout} = this.props;
        return <div className="full-size-width" onClick={logout}>
            <Icon type="logout" className="pr-2"/>
            <Trans>Odhlásiť</Trans>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.LOGOUT])(Logout);