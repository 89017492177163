export function isNumber(value) {
    return value != null && !Number.isNaN(Number(value));
}

export function isNumberAndGreaterThan(value, greaterThan) {
    return isNumber(value) && Number(value) > greaterThan;
}

export function isNumberAndGreaterOrEqual(value, greaterThan) {
    return isNumber(value) && Number(value) >= greaterThan;
}

export function numberOrNull(value){
	return (value != null && value !== "" && !Number.isNaN(Number(value))) ? Number(value) : null;
}

export function numberOrOriginal(value){
	return (value != null && value !== "" && !Number.isNaN(Number(value))) ? Number(value) : value;
}

export function sysDecimalSeparator() {
    var n = 1.1;
    n = n.toLocaleString().substring(1, 2);
    return n;
}