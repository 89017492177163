// @flow
import React from 'react';
import {LocationContext} from './LocationProvider';

/**
 * @fero
 */

export function locationHOC(Component) {
    return class extends React.PureComponent {
        render() {
            return <LocationContext.Consumer>
                {locationContext => (
                    <Component {...locationContext} {...this.props}/>
                )}
            </LocationContext.Consumer>;
        }
    }
};

export default locationHOC;