import PropTypes from 'prop-types';
import React from 'react';
import InputNumber from '../general/InputNumber';

/**
 * @fero
 */

class InputQuantity extends React.Component {
    static propTypes = {
        size: PropTypes.oneOf(['small', 'large']),
        wrapperClassName: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        unit: PropTypes.node,
    };

    render() {
        const {size, wrapperClassName, onChange, value, unit, ...props} = this.props;
        return <InputNumber
            {...props}
            size={size}
            wrapperClassName={wrapperClassName}
            value={value}
            onChange={onChange}
            after={unit != null ? <span className="ml-1 text-nowrap">{unit}</span> : null}
        />;
    }

}

export default InputQuantity;