import React from 'react';
import {Trans} from '@lingui/macro';

export const TRADE = {
    BUY: 'N',
    SELL: 'P',
};

export const TRADE_DISPLAY = {
    ORDER_BUY: <Trans>OO</Trans>,
    ORDER_SELL: <Trans>PO</Trans>,
    QUOTATION_BUY: <Trans>PP</Trans>,
    QUOTATION_SELL: <Trans>OP</Trans>,
    REQUEST_BUY: <Trans>OD</Trans>,
    REQUEST_SELL: <Trans>PD</Trans>,
};