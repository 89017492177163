import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip as AntdTooltip} from 'antd';

/**
 * @fero
 */

class Tooltip extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node,
        placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        stopPropagationOnClick: PropTypes.bool,
    };

    static defaultProps = {
        placement: 'top',
        stopPropagationOnClick: false,
    };

    onClick = (event) => {
        const {stopPropagationOnClick} = this.props;
        if(stopPropagationOnClick) {
            event.stopPropagation();
        }
    };



    render() {
        const {title, children, placement} = this.props;
        return title != null ? <AntdTooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.5}
            placement={placement}
            title={title}
            onClick={this.onClick}
        >
            {children}
        </AntdTooltip> :
        children;
    }

}

export default Tooltip;