import React from 'react';
import PropTypes from 'prop-types';
import {formatString} from '../../lib/formatting';
import {UserAddress} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class Address extends React.PureComponent {
    static propTypes = {
        address: UserAddress,
        className: PropTypes.string,
        nullOption: PropTypes.node,
    };

    static defaultProps = {
        className: "",
        nullOption: <Trans>neuvedené</Trans>
    };
    
    render() {
        const {address, nullOption, className} = this.props;
        if(address != null) {
            return <div className={className}>
                {address.organization != "" ? (<div>{address.organization}</div>) : null}
                <div>{address.street}</div>
                <div>{(address.zip_code != null && address.city != null && address.country) != null ?
                    formatString(address.zip_code, '') + " " + formatString(address.city, '') + ", " + formatString(address.country, '') :
                    null
                }</div>
            </div>;
        } else {
            return nullOption;
        }

    }

}

export default Address;