import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Exception from './Exception';
import {ASYNC_STATE} from '../../constants/AsyncState';
import {Button, Modal} from 'antd';
import {Trans, t} from '@lingui/macro';
import {stopPropagation} from '../../lib/interaction';

/**
 * @fero
 */

class FormModal extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        className: PropTypes.string,
        modalClassName: PropTypes.string,
        backgroundColor: PropTypes.string,
        values: PropTypes.object.isRequired,
        openNode: PropTypes.node.isRequired,
        onFinish: PropTypes.func,
        onFinishSuccessful: PropTypes.func,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        Form: PropTypes.func.isRequired,
        Response: PropTypes.func,
        Failed: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        className: "",
        modalClassName: "",
        backgroundColor: "white"
    };

    constructor(props) {
        super(props);
        this.first = React.createRef();
        this.modal = React.createRef();
        this.state = {
            submitFinishedSuccessful: false,
            response: null,
            visible: false,
            x0: 0,
            y0: 0,
            top0: 0,
            left0: 0,
            top: undefined,
            left: undefined,
            dragging: false,
        }
    };

    componentDidUpdate() {
        if (this.first != null) {
            const buttonNode = ReactDOM.findDOMNode(this.first.current);
            setTimeout(() => {
                if (buttonNode != null && buttonNode.focus != null) {
                    buttonNode.focus();
                }
            }, 0);
        }
    }

    handleFormOpen = () => {
        const {disabled, onOpen} = this.props;
        if(disabled == null || !disabled) {
            this.setState({
                visible: true
            });

            if(onOpen != null)
                onOpen();
        }
    };

    handleFormClose = () => {
        const {submitFinishedSuccessful, response} = this.state;
        this.handleFormCloseParametrized(response, submitFinishedSuccessful);
    };

    handleFormCloseParametrized = (response, submitFinishedSuccessful) => {
        const {onClose, onFinishSuccessful, onFinish} = this.props;
        this.setState({
            submitFinishedSuccessful: false,
            response: null,
            visible: false,
        });
        if (submitFinishedSuccessful && onFinishSuccessful != null) {
            const result = response != null ? response.result : null;
            onFinishSuccessful(result);
        }
        if (response != null && onFinish != null) {
            onFinish();
        }
        if (onClose != null) {
            onClose();
        }
    };

    handleSubmitFinish = (res) => {
        const {Response} = this.props;
        if (res.asyncState == ASYNC_STATE.SUCCEEDED) 
        {
            if (Response != null) 
            {
                this.setState({
                    submitFinishedSuccessful: true,
                    response: res,
                });
            } 
            else 
            {
                this.handleFormCloseParametrized(res, true);
            }
        } 
        else 
        {
            this.setState({
                response: res,
            });
        }
    };

    handleErrorDisplay() {
        const {Failed} = this.props;
        const {response} = this.state;
        if (response == null) {
            return null;
        }
        switch (response.asyncState) {
            case ASYNC_STATE.FAILED :
                return <Failed result={response.result}/>;
            case ASYNC_STATE.EXCEPTION :
                return <Exception response={response}/>;
            default :
                return <div>ERROR IN FRONT END, TRY TO REALOD PAGE, OR CONTACT SUPPORT</div>;
        }
    }

    handleResponseFooter() {
        const {} = this.props;
        const {response} = this.state;
        switch (response.asyncState) {
            case ASYNC_STATE.SUCCEEDED :
                return <Button
                    ref={this.first}
                    key="ok"
                    type="primary"
                    onClick={this.handleFormClose}
                >
                    <Trans>Zatvoriť</Trans>
                </Button>;
            default :
                return <div>ERROR IN FRONT END, TRY TO REALOD PAGE, OR CONTACT SUPPORT</div>;
        }
    }

    getModalNode = () => {
        if(this.modal == null)
            return null;

        const root = ReactDOM.findDOMNode(this.modal);

        if(root.childElementCount < 2)
            return null;

        const wrapper = root.childNodes[1];
        if(wrapper.childElementCount < 1)
            return null;

        return wrapper.childNodes[0];
    }

    handleMouseDown = (e) => {
        const node = this.getModalNode();
        if(node != null)
        { 
            e.preventDefault();
            
            document.onmousemove = this.handleMouseMove;
            document.onmouseup = this.handleMouseUp;

            const rect = node.getBoundingClientRect();
            const left = parseFloat(node.style.left);

            this.setState({
                dragging: true, 
                x0: e.clientX, 
                y0: e.clientY, 
                top0: rect.top,
                left0: Number.isFinite(left) ? left : 0
            });
        }
    }

    handleMouseMove = (e) => {
        const {dragging, left0, top0, x0, y0} = this.state;
        
        if(dragging)
        {
            e.preventDefault();
            
            const node = this.getModalNode();
            if(node != null)
            {
                node.style.top = `${top0 + e.clientY - y0}px`;
                node.style.left = `${left0 + e.clientX - x0}px`;
            }
        }
    }

    handleMouseUp = (e) => {
        e.preventDefault();
        document.onmousemove = null;
        this.setState({dragging: false});
    }

    render() {
        const {Form, values, title, openNode, Response, Failed, className, modalClassName, backgroundColor, ...props} = this.props;
        const {visible, response, submitFinishedSuccessful} = this.state;
        const displayResponse = submitFinishedSuccessful && Response != null;
        return <div className={`d-inline-flex ${className}`} onClick={stopPropagation} onKeyDown={stopPropagation}>
            <div className="full-size-width" onClick={this.handleFormOpen}>
                {openNode}
            </div>
            <Modal
                className={modalClassName}
                ref={node => this.modal = node}
                bodyStyle={{backgroundColor}}
                visible={visible}
                title={
                    <div
                        onMouseDown={this.handleMouseDown}
                        style={{cursor: 'move', width: '100%'}}
                    >
                        {title}
                    </div> 
                }
                onCancel={this.handleFormClose}
                destroyOnClose={true}
                keyboard={true}
                closable={true}
                maskClosable={false}
                footer={displayResponse ? this.handleResponseFooter() : null}
            >
                {displayResponse ?
                    <Response fetchResult={response.result} {...this.props}/> :
                    [
                        <Form
                            {...props}
                            key="form"
                            onSubmitFinish={this.handleSubmitFinish}
                            onClose={this.handleFormClose}
                            values={values}
                        />,
                        <div key="error">
                            {this.handleErrorDisplay()}
                        </div>
                    ]}
            </Modal>
        </div>
    }

}

export default FormModal;