// @flow
import React from 'react';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import {DATA_DEFS, DATA_DEFAULTS} from './dataDefs';
import * as dataKeysFunctions from './dataKeys';
import {locationHOC} from '../locationProvider/locationHOC';
import {ROUTES, ERROR_ROUTES, QUERY_PARAMS} from '../../constants/navigation';
import {getQueryParamNumber, getRelativePath, navigateToParametrized} from '../../lib/url';
import {hasRights} from '../../lib/project';
import {GLOBAL_DATA} from '../../constants/globalData';
import {ERROR_CODES} from '../../constants/errorCodes';
import {ASYNC_STATE} from '../../constants/AsyncState';
import deepEqual  from 'deep-equal';

/**
 * This component serves for fetching data. After fetch provides this data like content by data keys(GLOBAL_DATA).
 * For fetching data it's needed to specify data definition(dataDefs file) and dataKey(GLOBAL_DATA) for a path on which
 * data should be fetched(specified in dataKeys).
 * For accessing data use withDataHOC component.
 *
 * Component automatically fetches data on location change, based on data keys provided in dataKeys file.
 * All data which should be for automatically fetched should have specified data key and data definition.
 * Automatic fetch is performed when path in location is changed. In this case all of data keys specified for given
 * path in dataKeys file are fetched.
 * On search part of location data are fetch automatically as well. In this case fetch attributes which takes values
 * from location are compared with attributes in previous location. If there is a difference data are fetched with new
 * attributes. Which path search attributes are picked for fetching is specified in data definition.
 * It's needed to note that data are fetched also after change in rights(for example after user log in).
 *
 * Component also provides various functions for fetching data(these might be accessed by data key like data collections)
 * - reloadData - fetches data based on data key(GLOBAL_DATA), this data key must have corresponding data
 *      definition(in dataDefs). Data from reload are accessible under specified data key.
 * - fetchDataByDataDef - fetches data by providing data definition(mostly saved in dataDefs file). Data received
 *      from fetch are stored in data key specified in data definition. For examples of data definition look
 *      at dataDefs file.
 * - fetchHandler - fetches data separately of data keys and content structure. Enables fetch data directly and provides
 *      callbacks for successful and failed result of fetch.
 *
 * If there is an error while fetching application is redirected to error page. On this page error, which is result
 * of fetch, is displayed. This holds also for fetchHandler unless this behaviour is overridden by onFinishFailure
 * callback.
 *
 * @fero
 */

class DataProvider extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            //defaults for GLOBAL_DATA, not need to be speciefied, but if it's not it will be undefined and component
            // will not be rendered when wrapped in withDataHOC, same applies for null.
            ...DATA_DEFAULTS,
            //dataProvider variables
            loading: 0,
            dataLock: false,
            //dataProvider function that are provided through GLOBAL_DATA for data handling
            [GLOBAL_DATA.RELOAD_DATA]: this.reloadData,
            [GLOBAL_DATA.FETCH_HANDLER]: this.fetchHandler,
            [GLOBAL_DATA.FETCH_DATA]: this.fetchData,
            [GLOBAL_DATA.FETCH_BY_DATA_DEF]: this.fetchDataByDataDef,
            [GLOBAL_DATA.SET_DATA]: this.setToDataKey,
            //this is provided for lockerHOC not for public use so it is not defined by GLOBAL_DATA
            setDataLoadLock: this.setDataLoadLock,
        }
    }

    /*
     * Fetches data on initial, unless data are not set or session is locked.
     */
    componentDidMount() {
        const {rights, sessionReady} = this.props;
        if (rights != null && sessionReady) {
            const dataKeys = dataKeysFunctions.routeGlobalDataKeys(this.props.location, true);
            this.fetchDataByDataKeys(dataKeys);
        }
    }


    /*
     * Fetches data on attribute change if session is not locked and is not on error page(on error page data are not
     * needed).
     *
     * If rights or path is changed retrieves all data for given path. Removes any stored error, in case there were
     * any(this serves for navigation out of error page so error is forgotten).
     *
     *
     * If url search attributes is changed, retrieves data which are dependent on changed attributes.
     */
    componentDidUpdate(prevProps) {
        const {sessionReady, location, rights} = this.props;
        const {sessionReady: prevSessionReady, location: prevLocation, rights: prevRights} = prevProps;
        const rightsHasChanged = prevRights != rights;
        const locationPathHasChanged = prevLocation.pathname != location.pathname;
        const locationSearchHasChanged = prevLocation.search != location.search;
        const sessionReadyHasChanged = prevSessionReady != sessionReady;
        const isErrorPath = ERROR_ROUTES.includes(getRelativePath(location.pathname));

        if (sessionReady && !isErrorPath) 
        {
            if (sessionReadyHasChanged || rightsHasChanged || locationPathHasChanged)
            {
                const hasError = (this.state[GLOBAL_DATA.ERROR] != null && this.state[GLOBAL_DATA.ERROR] != '');
                const withBaseData = (rightsHasChanged || sessionReadyHasChanged || hasError);
                const dataKeys = dataKeysFunctions.routeGlobalDataKeys(location, withBaseData);
                this.fetchDataByDataKeys(dataKeys);

                if (hasError) 
                    this.setState({[GLOBAL_DATA.ERROR]: ''});
            } 
            else if (locationSearchHasChanged) 
            {
                const dataKeys = dataKeysFunctions.routeGlobalDataKeys(location, true);
                this.fetchDataOnSearchChange(dataKeys, location, prevLocation);
            }
        }
    };

    reloadData = (dataKeys) => {
        let reloadDataKeys = dataKeys;
        if (reloadDataKeys == null) {
            reloadDataKeys = dataKeysFunctions.routeGlobalDataKeys(this.props.location, false);
        }
        this.fetchDataByDataKeys(reloadDataKeys);
    };

    addToNumberOfLoadingData(number) {
        this.setState((prevState, props) => {
            const loading = prevState.loading + number;
            return {loading: loading};
        })
    }

    setDataLoadLock = (isLocked) => {
        this.setState({
            dataLock: isLocked,
        })
    };

    /*
    * Returns dataDefs array that corresponds to specified dataKeys.
    */
    dataDefsArrayForDataKeys = (dataKeys) => {
        let filteredDataDefsArray = [];
        dataKeys.forEach(dataKey => {
            if (DATA_DEFS[dataKey] != null) {
                filteredDataDefsArray.push(DATA_DEFS[dataKey]);
            } else {
                console.warn('There is no data definition for data key ' + dataKey + '.');
            }

        });
        return filteredDataDefsArray;
    };

    /*
    * Takes data keys and checks if their attributes for fetch props has changed. If yes takes dataDef for given
    * dataKey and based on that fetches data.
    */
    fetchDataOnSearchChange = (dataKeys, location, prevLocation) => {
        const filteredDataDefsArray = this.dataDefsArrayForDataKeys(dataKeys);
        const dataDefsToFetchArray = filteredDataDefsArray.filter(dataDef => {
            let included = false;
            if(dataDef.fetchPropsFunc != null) {
                included = this.hasAttributesChanged(dataDef.fetchPropsFunc, location, prevLocation);
            }
            return included;
        });
        this.fetchDataByDataDef(dataDefsToFetchArray);
    };

    /*
    * Helper function. Determines if there is change in attributes between locations. Attributes are defined by function.
    */
    hasAttributesChanged = (attributeFunction, location, prevLocation) => {
        const attributes = attributeFunction(location);
        const prevAttributes = attributeFunction(prevLocation);
        return !deepEqual(attributes, prevAttributes)
    };

    /*
     * Takes dataDefs by dataKeys and fetches data based on those dataDefs.
     *
     */
    fetchDataByDataKeys = (dataKeys) => {
        const filteredDataDefsArray = this.dataDefsArrayForDataKeys(dataKeys);
        this.fetchDataByDataDef(filteredDataDefsArray)
    };

    /*
     * This function fetches data to global data by data definitions. As it parameter is expects an array
     * of dataDefinitions which structure is:
     *   - dataKey: a key under which it should be loaded to global data(does not have to be initialized)
     *   - fetchFunc: a function that calls api for fetch.
     *   - fetchPropsFunc: function(it will have passed location as attribute) that returns object of properties
     *       for fetch function(will be passed as fetch function first attribute).
     *   - rightsFrom: if defined checks if current rights as higher or equal to defined in this attribute. If not
     *       they are simply not loaded.
     *   - rightsTo: if defined checks if current rights are less or equal to similar to rightsFrom.
     *
     * When data are loaded for each load it's added to loading data count and after fetch it's removed from there.
     * Thanks to this component that calls this function may be wrapped to Loading component in components/dataProvider
     * and thanks to that have loading animation.
     *
     */
    fetchDataByDataDef = (dataDefsArray) => {
        const {rights, location} = this.props;
        let numberOfItems = 0;
        dataDefsArray.map(dataDef => {
            if (hasRights(rights, dataDef.rightsFrom, dataDef.rightsTo)) {
                const fetchProps = dataDef.fetchPropsFunc != null ? dataDef.fetchPropsFunc(location) : {};
                if(dataDef.dataKey != null) {
                    if(dataDef.fetchFunc != null) {
                        this.fetchToDataKey(dataDef.fetchFunc, dataDef.dataKey, fetchProps);
                        numberOfItems++;
                    } else {
                        console.warn(
                            "Data definition doesn't contain fetch function. " +
                            "Definition object " + JSON.stringify(dataDef) + "."
                        );
                    }
                } else {
                    console.warn(
                        "Data definition doesn't contain dataKey. " +
                        "Definition object " + JSON.stringify(dataDef) + "."
                    );
                }
            }

        });
        this.addToNumberOfLoadingData(numberOfItems);
    };

    /*
     * Provides handler for fetch functions that handles communication errors(when there is a problem redirects to error
     * page and displays needed error).
     * First attribute is fetch function.
     * Second attribute is object with fetchProps(attributes of object will be send as query(GET) or in body(POST)
     *      depending on fetch function).
     * Third attribute is onFinishSuccessful which is called when fetch ends successfully. Called function may have
     *      one attribute and that is response of fetch.
     * Fourth attribute is onFinish which is called when fetch ends.
     * Fifth attribute is onFinishFailure which is called when fetch ends in failure. Called function may have
     *      one attribute and that is error of fetch. This method overrides default behaviour which is redirection
     *      to error page when set.
     * This is provided as fetchHandler by dataProvider(accessible by withDataHOC).
     */
    fetchHandler = (fetchFunction, fetchProps, onFinishSuccessful, onFinish, onFinishFailure) => {
        this.addToNumberOfLoadingData(1);
        this.fetchData(
            fetchFunction,
            fetchProps,
            onFinishSuccessful,
            () => {
                this.addToNumberOfLoadingData(-1);
                if(onFinish != null) {
                    onFinish();
                }
            },
            onFinishFailure
        );
    };

    /*
     * Fetches data by fetch function with fetchProps to dataKey of dataProvider.
     */
    fetchToDataKey = (fetchFunction, dataKey, fetchProps) => {
        this.fetchData(
            fetchFunction,
            fetchProps,
            (result) => {
                this.setState({[dataKey]: result});
            },
            () => {
                this.addToNumberOfLoadingData(-1)
            }
        );

    };

    /*
     * Performs fetch based on provided fetch function.
     * When successful calls onFinishSuccessful with attribute which is result of fetch.
     * Calls onFinish with no attribute(on success as well as when error happens).
     * When there is an problem sets error response to error data key of dataProvider and redirects to needed error page.
     * onFinishFailure when defined default behaviour(redirecting to error page) is replaced by calling onFinishFailure
     *      which has error as attribute(exceptions are still redirected).
     */
    fetchData = (fetchFunction, fetchProps, onFinishSuccessful, onFinish, onFinishFailure) => {
        const {location: startLocation} = this.props;
        fetchFunction(fetchProps).then((response) => {
            if(window.location.pathname != startLocation.pathname)
            {
                // ignore response, location has changed since last call
            }
            else if (response.asyncState == ASYNC_STATE.SUCCEEDED) 
            {
                // everything is OK
                if (onFinishSuccessful != null)
                    onFinishSuccessful(response.result);
            }
            else if (response.asyncState == ASYNC_STATE.FAILED) 
            {
                // something went wrong in API
                const {location} = this.props;
                const error = response.result != null ? response.result : null;
                const code = error != null && error.code != null ? error.code : null;
                const debug = getQueryParamNumber(location, QUERY_PARAMS.DEBUG);

                if (onFinishFailure != null) 
                {
                    // use given handler
                    onFinishFailure(error);
                } 
                else 
                {
                    // default behavior
                    if(code == ERROR_CODES.ACCESS_DENIED)
                    {
                        // go to login page
                        this.setState({[GLOBAL_DATA.ERROR]: error});
                        navigateToParametrized(location, ROUTES.LOGIN, {[QUERY_PARAMS.RETURN_TO]: getRelativePath(location.pathname)}, true);
                    }
                    else if(debug)
                    {
                        // show error message
                        this.setState({[GLOBAL_DATA.ERROR]: error});
                        navigateToParametrized(location, ROUTES.ERROR, {});
                    }
                    else
                    {
                        // mask error, just go home
                        console.log('error', error);
                        navigateToParametrized(location, ROUTES.HOME, {});
                    }
                }
            }
            else // response.asyncState == ASYNC_STATE.EXCEPTION
            {
                // something went wrong in frontend / network
                const {location} = this.props;

                const error = response.result != null ? response.result : null;
                this.setState({[GLOBAL_DATA.ERROR]: error});

                switch (response.status) 
                {
                    case 404:
                        navigateToParametrized(location, ROUTES.PAGE_404, {});
                        break;
                    case 500:
                        navigateToParametrized(location, ROUTES.PAGE_500, {});
                        break;
                    default:
                        navigateToParametrized(location, ROUTES.ERROR, {});
                }
            }

            if (onFinish != null)
                onFinish();
        });
    }

    /*
     * Sets data directly to dataKey of dataProvider.
     * Use with caution will affect global data, may rewrite any fetched data.
     */
    setToDataKey = (data) => {
        this.setState(data);
    };

    render() {
        if (this.state.loading < 0) {
            console.warn("Loading count degraded under 0. It's " + this.state.loading);
        }
        const {children} = this.props;
        return <DataContext.Provider value={this.state}>
            {children}
        </DataContext.Provider>;
    }

}

export default locationHOC(withSessionHOC()(DataProvider));

export const DataContext = React.createContext({
    loading: 0,
    dataLoadLock: false,
    [GLOBAL_DATA.RELOAD_DATA]: () => {},
    [GLOBAL_DATA.FETCH_HANDLER]: () => {},
    [GLOBAL_DATA.FETCH_DATA]: () => {},
    [GLOBAL_DATA.FETCH_BY_DATA_DEF]: () => {},
    [GLOBAL_DATA.SET_DATA]: () => {},
    setDataLoadLock: () => {},
});