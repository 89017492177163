import React from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Form, Radio} from 'antd';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../constants/form';
import {Trans, t} from '@lingui/macro';
import { EventObj } from '../../constants/propTypesDefinitions';
import { createFetchEventAdd, createFetchEventEdit } from '../../backend/apiCalls';
import EventTypesSelect from '../project/EventTypesSelect';
import DateTimePicker from '../general/DateTimePicker';
import VehiclesSelect from '../project/VehiclesSelect';
import CustomersSelect from '../project/CustomersSelect';
import AddressSelectByCustomer from '../project/AddressSelectByCustomer';
import { getInputOnChangeEventValue } from '../../lib/project';
import InputText from '../general/InputText';
import InputTextArea from '../general/InputTextArea';
import InputQuantity from '../project/InputQuantity';
import { dateTimeAdd, dateTimeDiff } from '../../lib/date';
import { merge } from '../../lib/object';
import UserSelectByCustomer from '../project/UserSelectByCustomer';
import { GLOBAL_DATA } from '../../constants/globalData';
import withDataHOC from '../dataProvider/withDataHOC';
import TimePicker from '../general/TimePicker';
import InputPrice from '../project/InputPrice';
import InputNumber from '../general/InputNumber';
import InputPercent from '../project/InputPercent';
const FormItem = Form.Item;

/**
 * @dusan
 */


 class EventForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            eventObj: EventObj,
            isEdit: PropTypes.bool,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            useVehicle: false,
            useRefuel: false,
            customerId: undefined,
            customerObj: null,
            startAt: null,
        };
    }

    componentDidMount() {
        const {values, [GLOBAL_DATA.EVENT_TYPES]: eventTypes, [GLOBAL_DATA.VEHICLES]: vehicles, [GLOBAL_DATA.ACCOUNT_INFO]: account} = this.props;
        const {eventObj, isEdit} = values;

        if(isEdit && eventObj != null)
        {
            // derive state from event obj
            const useVehicle = eventObj.id_vehicle != null;
            const useRefuel = eventObj.id_vehicle != null && eventObj.refuel_quantity > 0;
            const startAt = eventObj.start_at != null ? eventObj.start_at : null;
    
            this.setState({
                useRefuel: useRefuel,
                useVehicle: useVehicle,
                startAt: startAt,
            });
        }
        else
        {
            // set default event type
            const defaultType = eventTypes != null ? eventTypes.find(t => t.is_default) : null;
            if(defaultType != null)
            {
                const vehicle = (account != null && defaultType.is_away) ? 
                    vehicles.find(v => v.id_user == account.id) : null;

                const vehicleId = vehicle != null ? vehicle.id : null;

                this.props.form.setFieldsValue({
                    id_type: defaultType.id,
                    duration: defaultType.default_duration,
                    id_vehicle: vehicleId
                });

                this.setState({
                    userRefuel: false,
                    useVehicle: (vehicle != null),
                    startAt: null
                });
            }
        }
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                const {isEdit, eventObj ={}} = values;
                const fetchFunction = isEdit ? createFetchEventEdit() : createFetchEventAdd();
                let checkedValues = isEdit ?
                    merge(formValues, {id: eventObj.id}) : formValues;

                if(!checkedValues.use_refuel)
                {
                    checkedValues.refuel_quantity = null;
                    checkedValues.refuel_price_brutto = null;
                    checkedValues.refuel_place = null;
                    checkedValues.refuel_at = null;
                }

                this.setState({loading: true});
                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    onVehicleChange = (vehicleId) => {
        this.setState({useVehicle: (vehicleId != null)});
    };

    onCustomerChange = (newCustomer) => {
        const newCustomerId = newCustomer != null ? newCustomer.id : null;
        const newAddressId = newCustomer != null ? newCustomer.id_address : null;
        this.setState({customerId: newCustomerId, customerObj: newCustomer});
        this.props.form.setFieldsValue({
            id_address: newAddressId
        });
    };

    onUsersChange = (newUsers) => {
        const {[GLOBAL_DATA.EVENT_TYPES]: eventTypes} = this.props;
        const {customerObj} = this.state;
        const formObj = this.props.form;

        if(newUsers != null && customerObj != null)
        {
            const eventTypeId = formObj.getFieldValue('id_type');
            const eventType = eventTypeId != null ? eventTypes.find(t => t.id == eventTypeId) : null;
            if(eventType != null && eventType.is_away)
            {
                // select address by customer's user
                const userWithAddress = (
                    Array.isArray(newUsers) ? 
                        newUsers.find(u => u.id_customer == customerObj.id && u.id_delivery_address != null)
                        :
                        (newUsers.id_delivery_address != null ? newUsers : null)
                );

                const addressId = userWithAddress != null ? userWithAddress.id_delivery_address : customerObj.id_address;
                if(addressId != null)
                {
                    formObj.setFieldsValue({
                        id_address: addressId
                    });
                }
            }
        }
    };

    onRefuelChange = (e) => {
        const val = getInputOnChangeEventValue(e);
        this.setState({useRefuel: val});
    };

    onStartAtChange = (newStartAt) => {
        const formObj = this.props.form;
        this.setState({startAt: newStartAt});

        if(newStartAt != null)
        {
            const duration = formObj.getFieldValue('duration');
            const endAt = dateTimeAdd(newStartAt, duration);
            formObj.setFieldsValue({end_at: endAt});
        }
    };

    onDurationChange = (newDuration) => {
        const formObj = this.props.form;
        const {startAt} = this.state;
        
        if(startAt != null)
        {
            const endAt = dateTimeAdd(startAt, newDuration);
            formObj.setFieldsValue({end_at: endAt});
        }
    };

    onEndAtChange = (newEndAt) => {
        const formObj = this.props.form;
        const {startAt} = this.state;
        
        if(newEndAt != null && startAt != null)
        {
            const duration = dateTimeDiff(newEndAt, startAt);
            formObj.setFieldsValue({duration: duration});
        }
    };

    onTypeChange = (newTypeId) => {
        const {[GLOBAL_DATA.EVENT_TYPES]: eventTypes, [GLOBAL_DATA.ACCOUNT_INFO]: account, [GLOBAL_DATA.VEHICLES]: vehicles} = this.props;
        const formObj = this.props.form;

        const newType = newTypeId != null ? eventTypes.find(e => e.id == newTypeId) : null;
        if(newType != null && newType.default_duration != null)
        {
            // set default duration
            const {startAt} = this.state;
            const endAt = dateTimeAdd(startAt, newType.default_duration);
            formObj.setFieldsValue({duration: newType.default_duration, end_at: endAt});
        }

        if(newType != null)
        {
            const vehicle = (account != null && newType.is_away) ? 
                    vehicles.find(v => v.id_user == account.id) : null;

            // set default vehicle
            formObj.setFieldsValue({id_vehicle: vehicle != null ? vehicle.id : null});
            this.setState({useVehicle: (vehicle != null)});
        }
    }

    render() {
        const {loading, useVehicle, useRefuel, customerId, startAt} = this.state;
        const {
            values: {isEdit, eventObj = {}, customerId: fixedCustomerId}, 
            onClose, [GLOBAL_DATA.ACCOUNT_INFO]: accountInfo
        } = this.props;
        const {getFieldDecorator} = this.props.form;
        const durationTim = eventObj.duration != null ? eventObj.duration : '00:30';
        const customerIdChecked = fixedCustomerId != null ? 
            fixedCustomerId : (customerId === undefined ? eventObj.id_customer : customerId);

        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Typ</Trans>}
            >
                {getFieldDecorator('id_type', {
                    rules: [mandatoryRule], 
                    initialValue: eventObj.id_type,
                    onChange: this.onTypeChange
                })(
                    <EventTypesSelect focus={true}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Začiatok</Trans>}
            >
                {getFieldDecorator('start_at', {
                    rules: [mandatoryRule], 
                    initialValue: isEdit ? eventObj.start_at : null,
                    onChange: this.onStartAtChange
                })(
                    <DateTimePicker 
                        step={900}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Trvanie</Trans>}
            >
                {getFieldDecorator('duration', {
                    rules: [mandatoryRule], 
                    initialValue: durationTim,
                    onChange: this.onDurationChange
                })(
                    <TimePicker
                        step={900}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Koniec</Trans>}
            >
                {getFieldDecorator('end_at', {
                    rules: [mandatoryRule], 
                    initialValue: isEdit ? eventObj.end_at : null,
                    onChange: this.onEndAtChange
                })(
                    <DateTimePicker
                        step={900}
                        min={startAt}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('title', {initialValue: eventObj.title})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Popis</Trans>}
            >
                {getFieldDecorator('description', {initialValue: eventObj.description})(
                    <InputTextArea/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Organizácia</Trans>}
            >
                {getFieldDecorator('id_customer', {initialValue: customerIdChecked})(
                    <CustomersSelect 
                        disabled={fixedCustomerId != null}
                        onChangeWithData={this.onCustomerChange}
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Adresa</Trans>}
            >
                {getFieldDecorator('id_address', {initialValue: eventObj.id_address})(
                    <AddressSelectByCustomer 
                        customerId={customerIdChecked}
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Miesto</Trans>}
            >
                {getFieldDecorator('place', {initialValue: eventObj.place})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Účastníci</Trans>}
            >
                {getFieldDecorator('id_participant', {initialValue: eventObj.participants != null ? eventObj.participants.map(p => p.id_user) : accountInfo.id})(
                    <UserSelectByCustomer 
                        customerId={customerIdChecked != null ? [customerIdChecked, accountInfo.id_customer] : [accountInfo.id_customer]}
                        isMulti={true}
                        onChangeWithData={this.onUsersChange}
                        addOption={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Upozornenie</Trans>}
            >
                {getFieldDecorator('alert_preset', {initialValue: eventObj.alert_preset})(
                    <InputNumber
                        allowClear={true}
                        min={0}
                        step={1}
                        after={
                            <span className="ml-2">
                                <Trans>prac. dní vopred</Trans>
                            </span>
                        }
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Vozidlo</Trans>}
            >
                {getFieldDecorator('id_vehicle', {
                    initialValue: eventObj.id_vehicle,
                    onChange: this.onVehicleChange
                })(
                    <VehiclesSelect/>
                )}
            </FormItem>
            { useVehicle ? 
                <React.Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Prejdená vzdialenosť</Trans>}
                    >
                        {getFieldDecorator('distance', {initialValue: eventObj.is_distance_fixed ? eventObj.distance : undefined})(
                            <InputQuantity 
                                placeholder="Automaticky"
                                min={0}
                                step={1}
                                unit="km"
                            />
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Typ spotreby</Trans>}
                    >
                        {getFieldDecorator('is_local', {initialValue: eventObj.is_local})(
                            <Radio.Group>
                                <Radio.Button value={1}><Trans>Mesto</Trans></Radio.Button>
                                <Radio.Button value={0}><Trans>Mimo mesto</Trans></Radio.Button>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <Divider orientation="left"><Trans>Tankovanie</Trans></Divider>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Zadať tankovanie</Trans>}
                    >
                        {getFieldDecorator('use_refuel', {
                            initialValue: useRefuel,
                            onChange: this.onRefuelChange
                        })(
                            <Radio.Group>
                                <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                                <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                            </Radio.Group>
                        )}
                    </FormItem>
                    { useRefuel ? 
                        <React.Fragment>
                            <FormItem
                                {...formItemLayout}
                                label={<Trans>Objem</Trans>}
                            >
                                {getFieldDecorator('refuel_quantity', {rules: [mandatoryRule], initialValue: eventObj.refuel_quantity})(
                                    <InputQuantity
                                        min={0}
                                        step={0.01}
                                        unit="L"
                                    />
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label={<Trans>Cena s DPH</Trans>}
                            >
                                {getFieldDecorator('refuel_price_brutto', {rules: [mandatoryRule], initialValue: eventObj.refuel_price_brutto})(
                                    <InputPrice
                                        min={0}
                                        step={0.01}
                                    />
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label={<Trans>Sadzba DPH</Trans>}
                            >
                                {getFieldDecorator('refuel_tax_rate', {initialValue: eventObj.refuel_tax_rate})(
                                    <InputPercent
                                        min={0}
                                        step={0.1}
                                        placeholder={'20'}
                                    />
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label={<Trans>Čas</Trans>}
                            >
                                {getFieldDecorator('refuel_at', {rules: [mandatoryRule], initialValue: eventObj.refuel_at != null ? eventObj.refuel_at : startAt})(
                                    <DateTimePicker/>
                                )}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label={<Trans>Miesto</Trans>}
                            >
                                {getFieldDecorator('refuel_place', {rules: [mandatoryRule], initialValue: eventObj.refuel_place})(
                                    <InputText/>
                                )}
                            </FormItem>
                        </React.Fragment> : 
                        null
                    }
                    <Divider orientation="left"><Trans>Koncový stav</Trans></Divider>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Počítadlo kilometrov</Trans>}
                    >
                        {getFieldDecorator('end_km', {initialValue: eventObj.is_km_fixed ? eventObj.end_km : undefined})(
                            <InputQuantity 
                                placeholder="Automaticky"
                                min={0}
                                step={1}
                                unit="km"
                            />
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Zostatok v nádrži</Trans>}
                    >
                        {getFieldDecorator('end_fuel_quantity', {initialValue: eventObj.is_fuel_fixed ? eventObj.end_fuel_quantity : undefined})(
                            <InputQuantity
                                placeholder="Automaticky"
                                min={0}
                                step={0.01}
                                unit="L"
                            />
                        )}
                    </FormItem>
                </React.Fragment> : null
            }
            <FormItem
                {...formItemLayout}
                label={<Trans>Odoslať pozvánku</Trans>}
            >
                {getFieldDecorator('send', {initialValue: isEdit ? 0 : 1})(
                    <Radio.Group>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(
    withDataHOC([
        GLOBAL_DATA.EVENT_TYPES, 
        GLOBAL_DATA.CUSTOMERS, 
        GLOBAL_DATA.ACCOUNT_INFO,
        GLOBAL_DATA.VEHICLES
    ])(EventForm)
);