// @flow
import React from 'react';
import {DataContext} from './DataProvider';

/**
 * HOC which servers for wrapping components that need to access data from data provider.
 * First argument should be array of data keys. Based on these corresponding data collections are pushed to props
 * of wrapped component.
 * Second argument is component which should be wrapped.
 *
 * In case any of data keys are null or undefined wrapped component is not rendered. Instead an empty div is rendered.
 *
 * @fero
 */

const withDataHOC = (params) => (Component) => {
    return class extends React.Component {
        render() {
            return <DataContext.Consumer>
                {context => {
                    const checkedParams = params != null ? params : [];
                    const strippedContext = {};
                    if (Array.isArray(checkedParams)) {
                        for (let i = 0; i < checkedParams.length; i++) {
                            const currentParam = checkedParams[i];
                            strippedContext[currentParam] = context[currentParam];
                        }
                    } else {
                        console.warn('Params for data context was not an array, it was ' + checkedParams)
                    }
                    if(Object.values(strippedContext).includes(undefined)) {
                        return <div/>
                    } else {
                        return <Component  {...strippedContext} {...this.props}/>
                    }
                }}
            </DataContext.Consumer>;
        }
    }
};

export default withDataHOC;