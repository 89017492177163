/**
 * @author fero
 */

export function getByIdAttribute(array, id) {
    for(let i = 0; i < array.length; i++) {
        if(array[i].id == id) {
            return array[i];
        }
    }
}

export function concatUnique(array1, array2) {
    return  array1.concat(array2.filter(function (item) {
        return array1.indexOf(item) < 0;
    }));
}

export function move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export function moveUp(arr, index) {
    if(index <= 0 || index >= arr.length)
        return arr;
    return [...arr.slice(0, index-1), arr[index], arr[index-1], ...arr.slice(index+1)];
}

export function moveDown(arr, index) {
    if(index < 0 || index >= arr.length-1)
        return arr;
    return [...arr.slice(0, index), arr[index+1], arr[index], ...arr.slice(index+2)];
}

export function arrayUnique(arr) {
    return arr.filter((v, i, a) => a.indexOf(v) === i);
}