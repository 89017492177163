import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../fetch/FormModal';
import generalFailedPC from '../fetch/generalFailedPC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import EventForm from './EventForm';
import { EventObj } from '../../constants/propTypesDefinitions';
import Tooltip from '../general/Tooltip';

/**
 * @dusan
 */


class EventEdit extends React.PureComponent {
    static propTypes = {
        wrapperClassName: PropTypes.string,
        eventObj: EventObj.isRequired,
        reload: PropTypes.func.isRequired,
        type: PropTypes.string,
    };

    static defaultProps = {
        wrapperClassName: '',
        type: 'button'
    };

    render() {
        const {wrapperClassName, eventObj, reload, type} = this.props;
        return <FormModal
            className={wrapperClassName}
            openNode={
                <Tooltip title={<Trans>Upraviť event</Trans>}>
                    { type == 'link' ? 
                        <div className="pl-2 link-button"><Trans>Upraviť</Trans></div> 
                        : <Button className="mx-1" size="small" icon="edit"/>
                    }
                </Tooltip>
            }
            values={{isEdit: true, eventObj: eventObj}}
            onFinishSuccessful={reload}
            title={<Trans>Upraviť event</Trans>}
            Form={EventForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť event.`)}
        />;
    }

}

export default EventEdit;