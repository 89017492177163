import React from 'react'
import PropTypes from 'prop-types'
import {withCookies} from 'react-cookie';
import CookieConsent from "react-cookie-consent";
import Link from '../components/navigation/Link';
import {Trans} from '@lingui/macro';
import {ROUTES, CONSENT_COOKIE} from '../constants/navigation';
import 'whatwg-fetch';
import withSessionHOC from '../components/sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../components/sessionProvider/SessionProvider';
import { RIGHTS } from '../constants/Rights';
/**
 * @fero
 */

class EshopCookieConsent extends React.PureComponent {
    static propTypes = {
        gtag: PropTypes.func.isRequired,
    };

    render() {
        const {[SESSION_ATTRIBUTES.GTAG]: gtag, [SESSION_ATTRIBUTES.RIGHTS]: rights, cookies} = this.props;
        const cookieConsentValue = cookies.get(CONSENT_COOKIE);
        if (cookieConsentValue != 'yes' && cookieConsentValue != 'no' && rights < RIGHTS.MARKETING) 
        {
            return <CookieConsent
                enableDeclineButton
                buttonText={<Trans>Prijímam</Trans>}
                declineButtonText={<Trans>Neprijímam</Trans>}
                cookieName={CONSENT_COOKIE}
                onDecline={() => {
                    cookies.set(CONSENT_COOKIE, 'no', {path: '/', maxAge: 86400});
                }}
                onAccept={() => {
                    cookies.set(CONSENT_COOKIE, 'yes', {path: '/'});
                    gtag('consent', 'update', {
                        ad_user_data: 'granted',
                        ad_personalization: 'granted',
                        ad_storage: 'granted',
                        analytics_storage: 'granted'
                    });
                }}
            >
                <Trans>Táto stránka používa cookies pre zlepšenie používania.</Trans>
                <Trans>Pre viac informácií si môžete prezrieť</Trans>
                <Link className="px-2 text-white" to={ROUTES.PERSONAL_DATA_PROTECTION}>
                    <Trans>Zásady ochrany osobných údajov.</Trans>
                </Link>
            </CookieConsent>;
        } 
        else 
        {
            return null;
        }
    }

}

export default withCookies(withSessionHOC([SESSION_ATTRIBUTES.GTAG, SESSION_ATTRIBUTES.RIGHTS])(EshopCookieConsent));