import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import { Button, Icon } from 'antd';
import {Trans, t} from '@lingui/macro';
import Tooltip from '../general/Tooltip';
import DisplayModal from '../general/DisplayModal';
import { Customer } from '../../constants/propTypesDefinitions';
import { createFetchNotesSearch } from '../../backend/apiCalls';
import AddNote from './customerNotes/AddNote';
import NotesTree from './customerNotes/NotesTree';
import Spinner from '../graphics/Spinner';
import InputText from '../general/InputText';

/**
 * Provides chat about customers with multiple threads
 *
 * @dusan
 */

class CustomerNotes extends React.PureComponent {
    static propTypes = {
        customer: Customer,
        title: PropTypes.node,
        type: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
            phrase: undefined,
        };
    }
    
    toggleSearch = () => {
        const {showSearch} = this.state;
        if(showSearch)
            this.setState({showSearch: false, phrase: undefined});
        else 
            this.setState({showSearch: true});  
    }

    onPhraseChange = (str) => {
        this.setState({phrase: str});
    }

    onPhraseClear = () => {
        this.setState({phrase: undefined});
    }

    render() {
        const {customer, title, type} = this.props;
        const {showSearch, phrase} = this.state;
        const customerChecked = customer != null ? customer : {};
       
        return <DisplayModal
            className={type == 'link' ? 'full-size-width' : undefined}
            openNode={ type == 'link' ?
                <div className="full-size-width">
                    <Icon type="message" className="pr-2" style={{fontSize: "120%"}}/>
                    <Trans>Chat ku organizácii</Trans>
                </div>
                : <Tooltip title={<Trans>Chat ku organizácii</Trans>}>
                    <Button>
                        <Icon type="message" style={{fontSize: "120%"}}/>
                        {title != null ? 
                            <span className="ml-2">{title}</span> :
                            null
                        }
                    </Button>
                </Tooltip>
            }
            title={
                <div className="d-flex flex-wrap align-items-center">
                    <Trans>Chat ku</Trans>{' '}
                    { customerChecked.name}
                    <div className="px-2 d-flex">
                        <Button icon="search" onClick={this.toggleSearch}/>
                        { showSearch ? 
                            <InputText
                                className="customer-notes-search-input"
                                value={phrase}
                                onChange={this.onPhraseChange}
                                placeholder={t`Hľadať text...`}
                                autoFocus={true}
                            /> :
                            null
                        }
                        { showSearch && phrase != null && phrase.trim() != "" ?
                            <Button
                                type="danger"
                                icon="delete"
                                onClick={this.onPhraseClear}
                            /> : null
                        }
                    </div>
                </div>
            }
            modalClassName="middle-modal"
        >
            <CustomerNotesWrapped 
                customerId={customerChecked.id}
                phrase={phrase}
            />
        </DisplayModal>;
    }
}

export default CustomerNotes;

class CustomerNotesContent extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number.isRequired,
        phrase: PropTypes.string,
        [GLOBAL_DATA.FETCH_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notes: [],
        };
    }

    componentDidMount() {
        this.fetchNotes();
    }

    componentDidUpdate(prevProps) {
        const {customerId: prevCustomerId, phrase: prevPhrase} = prevProps;
        const {customerId, phrase} = this.props;
        if(customerId != prevCustomerId || phrase != prevPhrase)
            this.fetchNotes();
    }

    fetchNotes = () => {
        const {customerId, phrase, [GLOBAL_DATA.FETCH_DATA]: fetchHandler} = this.props;
        this.setState({loading: true});
        fetchHandler(
            createFetchNotesSearch(),
            {
                id_customer: customerId,
                phrase: phrase,
            },
            (result) => {
                this.setState({
                    notes: result,
                    loading: false
                })
            },
        );
    }

    render() {
        const {customerId} = this.props;
        const {loading, notes} = this.state;
        return <div className="full-size-width">
            <AddNote
                customerId={customerId}
                onFinish={this.fetchNotes}
            />
            { loading ? <Spinner/> : <NotesTree notes={notes} reload={this.fetchNotes}/> }
        </div>;
    }
}

const CustomerNotesWrapped = withDataHOC([GLOBAL_DATA.FETCH_DATA])(CustomerNotesContent);