import React from 'react';
import PropTypes from 'prop-types';
import CountriesSelect from '../../../../project/CountriesSelect';
import CustomersSelect from '../../../../project/CustomersSelect';
import CustomerRolesSelect from '../../../../project/CustomerRolesSelect';
import {merge} from '../../../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Divider, Form, Input, Radio} from 'antd';
import {createUserRegister} from '../../../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../../../constants/form';
import {RIGHTS} from '../../../../../constants/Rights';
import RightsWrapper from '../../../../sessionProvider/RightsWrapper';
import SupervisorsSelect from '../../../../project/SupervisorsSelect';
import InputTextArea from '../../../../general/InputTextArea';
const FormItem = Form.Item;

/**
 * @fero
 */

const mandatory = { rules: [mandatoryRule] };

class AddUserForm extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerId: PropTypes.number,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            setDeliveryAddress: false,
            setInvoiceAddress: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0);
    }

    onSetInvoiceAddressChange = (ev) => {
        this.setState({ setInvoiceAddress: ev.target.value });
    };

    onSetDeliveryAddressChange = (ev) => {
        this.setState({ setDeliveryAddress: ev.target.value });
    };

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {customerId} = values;
        e.preventDefault();
        this.props.form.validateFields((err, formValues) => {
            if (!err) 
            {
                this.setState({loading: true});
                const fetchArgs = customerId != null ? merge(formValues, {
                    id_customer: customerId
                }) : formValues;
                createUserRegister()(fetchArgs).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading, setInvoiceAddress, setDeliveryAddress} = this.state;
        const {onClose, values} = this.props;
        const {getFieldDecorator} = this.props.form;
        const { customerId } = values;
        return (
            <Form onSubmit={this.handleSubmit} className="">
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Titul</Trans>}
                >
                    {getFieldDecorator('title', {initialValue: ''})(
                        <Input ref={node => this.first = node}/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Meno</Trans>}
                >
                    {getFieldDecorator('name', mandatory)(
                        <Input/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Priezvisko</Trans>}
                >
                    {getFieldDecorator('surname', mandatory)(
                        <Input/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Email</Trans>}
                >
                    {getFieldDecorator('e_mail', {})(
                        <Input/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Telefón</Trans>}
                >
                    {getFieldDecorator('phone', {})(
                        <Input/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Heslo</Trans>}
                >
                    {getFieldDecorator('password', {})(
                        <Input/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Rola</Trans>}
                >
                    {getFieldDecorator('access_level', { rules: [mandatoryRule], initialValue: RIGHTS.CUSTOMER })(
                        <CustomerRolesSelect/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Organizácia</Trans>}
                >
                    {getFieldDecorator('id_customer', {rules: [mandatoryRule], initialValue: customerId})(
                        <CustomersSelect disabled={(customerId != null)} className=""/>
                    )}
                </FormItem>
                <FormItem
                {...formItemLayout}
                label={<Trans>Pracovná pozícia</Trans>}
            >
                {getFieldDecorator('position', {})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámky</Trans>}
            >
                {getFieldDecorator('notices', {})(
                    <InputTextArea/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.SUPERVISOR}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Nadriadený</Trans>}
                >
                    {getFieldDecorator('id_supervisor', {})(
                        <SupervisorsSelect/>
                    )}
                </FormItem>
            </RightsWrapper>
                <Divider orientation="left"><Trans>Fakturačná adresa</Trans></Divider>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Zadať</Trans>}
                >
                    {getFieldDecorator('set_invoice_address', {
                        initialValue: setInvoiceAddress,
                    })(
                        <Radio.Group
                            onChange={this.onSetInvoiceAddressChange}
                        >
                            <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                        </Radio.Group>
                    )}
                </FormItem>
                { setInvoiceAddress ? <React.Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Ulica</Trans>}>
                        {getFieldDecorator('invoice_address[street]', mandatory)(
                            <Input/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Mesto</Trans>}>
                        {getFieldDecorator('invoice_address[city]', mandatory)(
                            <Input/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>PSČ</Trans>}>
                        {getFieldDecorator('invoice_address[zip_code]', mandatory)(
                            <Input/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Krajina</Trans>}>
                        {getFieldDecorator('invoice_address[id_country]', mandatory)(
                            <CountriesSelect/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Názov organizácie</Trans>}>
                        {getFieldDecorator('invoice_address[organization]', {})(
                            <Input/>
                        )}
                    </FormItem>
                </React.Fragment> : null }
                <Divider orientation="left"><Trans>Adresa pre doručenie tovaru</Trans></Divider>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Zadať</Trans>}
                >
                    {getFieldDecorator('set_delivery_address', {
                        initialValue: setDeliveryAddress,
                    })(
                        <Radio.Group
                            onChange={this.onSetDeliveryAddressChange}
                        >
                            <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                            <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                        </Radio.Group>
                    )}
                </FormItem>
                { setDeliveryAddress ? <React.Fragment>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Ulica</Trans>}>
                        {getFieldDecorator('delivery_address[street]', mandatory)(
                            <Input disabled={!setDeliveryAddress}/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Mesto</Trans>}>
                        {getFieldDecorator('delivery_address[city]', mandatory)(
                            <Input disabled={!setDeliveryAddress}/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>PSČ</Trans>}>
                        {getFieldDecorator('delivery_address[zip_code]', mandatory)(
                            <Input disabled={!setDeliveryAddress}/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Krajina</Trans>}>
                        {getFieldDecorator('delivery_address[id_country]', mandatory)(
                            <CountriesSelect disabled={!setDeliveryAddress}/>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={<Trans>Názov organizácie</Trans>}>
                        {getFieldDecorator('delivery_address[organization]', {})(
                            <Input disabled={!setDeliveryAddress}/>
                        )}
                    </FormItem>
                </React.Fragment> : null }
                <FormItem
                    {...tailFormItemLayout}
                >
                    <Button onClick={onClose}>
                        <Trans>Zrušiť</Trans>
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        <Trans>Vytvoriť</Trans>
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(AddUserForm);