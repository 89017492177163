import React from 'react'
import PropTypes from 'prop-types';
import {Link as GatsbyLink} from 'gatsby';
import Tooltip from '../general/Tooltip';
import {locationHOC} from '../locationProvider/locationHOC';
import {relativeUrlWithQueryParams} from '../../lib/url';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class Link extends React.PureComponent {
    static propTypes = {
        isBackNavigation: PropTypes.bool,
        //if isBackNavigation is true ignores to and queryParams props and navigates to last path(last entry to history)
        to: PropTypes.string,
        queryParams: PropTypes.object,
        title: PropTypes.node,//title to be displayed in tooltip
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
    };

    render() {
        const {location, to, queryParams, title, children, className, disabled, goBack, isBackNavigation, onClick} = this.props;
        const checkedQueryParams = queryParams != null ? queryParams : {};
        const toWithParams = relativeUrlWithQueryParams(location, to, checkedQueryParams);
        const childrenChecked = children != null ? children : <Trans>zobraziť</Trans>;
        return <GatsbyLink
            className={(disabled ? 'disabled-link ' : '') + (className != null ? className : '')}
            to={toWithParams}
            onClick={(event) => {
                event.stopPropagation();
                if(disabled) 
                {
                    event.preventDefault();
                } 
                else 
                {
                    if(isBackNavigation) 
                    {
                        event.preventDefault();
                        goBack();
                    }
                }
                
                if(onClick != null)
                    onClick();
            }}
        >
            {title != null ? <Tooltip title={title}>{childrenChecked}</Tooltip> : childrenChecked}
        </GatsbyLink>;
    }

}

export default locationHOC(Link);