import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
const TextArea = Input.TextArea;

/**
 * Component that enables input text(area). onChange event is fired only
 * when return is pressed or when focus is lost.
 *
 * @fero
 */

class InputTextArea extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
        changeImmediately: PropTypes.bool,
        autoSize: PropTypes.shape({
            minRows: PropTypes.number.isRequired,
            maxRows: PropTypes.number.isRequired,
        }),
    };

    static defaultProps = {
        autosize: {minRows: 2, maxRows: 5},
        changeImmediately: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            itsValue: props.value,
            itsLastValue: props.value,
        };
    }

    componentDidMount() {
        const {value} = this.props;
        this.setState({
            itsValue: value,
            itsLastValue: value,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {value} = this.props;
        const {itsLastValue} = this.state;
        const {itsLastValue: itsPrevValue} = prevState;
        if(itsPrevValue == itsLastValue && value !== itsLastValue)
        {
            this.setState({
                itsValue: value,
                itsLastValue: value,
            });
        }
    }

    focus() {
        if(this.input != null) 
            this.input.focus();
    }

    onChanged = (ev) => {
        const {changeImmediately, onChange} = this.props;
        this.setState({itsValue: ev.target.value});
        if(changeImmediately == true && onChange != null)
            onChange(ev.target.value);
    };

    onBlur = () => {
        const {itsValue, itsLastValue} = this.state;
        const {onChange} = this.props;
        if(itsValue != itsLastValue) {
            this.setState({ itsLastValue: itsValue });

            if(onChange != null)
                onChange(itsValue);
        }
    };

    render() {
        const {value, onChange, autoSize, changeImmediately, ...props} = this.props;
        const {itsValue} = this.state;
        return <TextArea
            {...props}
            autoSize={autoSize}
            onChange={this.onChanged}
            onBlur={this.onBlur}
            value={itsValue}
            ref={node => this.input = node}
        />;
    }

}

export default InputTextArea;