import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {createFetchUserAll} from '../../backend/apiCalls';
import { formatUserInfoName } from '../../lib/formatting';
import FormModal from '../fetch/FormModal';
import { Button, Icon } from 'antd';
import AddUserForm from '../customers/customers/table/form/AddUserForm';
import {Trans, t} from '@lingui/macro';
import generalFailedPC from '../fetch/generalFailedPC';
import deepEqual from 'deep-equal';
import { RIGHTS } from '../../constants/Rights';

/**
 * Provides filled user select. Users are filtered based on customer id. Users should be as defined in UserIndex
 * in propTypes.
 *
 * @fero
 */

class UserSelectByCustomer extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        focus: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onChangeWithData: PropTypes.func,
        allowClear: PropTypes.bool,
        addOption: PropTypes.bool,
        className: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            users: [],
        }
    }

    componentDidMount() {
        this.fetchUsers();
    }

    componentDidUpdate(prevProps) {
        const {customerId} = this.props;
        const {customerId: prevCustomerId} = prevProps;
        if(!deepEqual(customerId, prevCustomerId)) {
            this.fetchUsers();
        }
    }

    onChanged = (newUserId) => {
        const {users} = this.state;
        const {onChange, onChangeWithData} = this.props;
        onChange(newUserId);

        if(onChangeWithData != null)
        {
            const newUser = newUserId != null && users != null ? 
                ( Array.isArray(newUserId) ? 
                    users.filter(u => newUserId.includes(u.id))
                    :
                    users.find(u => u.id == newUserId)
                ) 
                : 
                null;

            onChangeWithData(newUser);
        } 
    }

    fetchUsers = () => {
        const {customerId, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        fetchHandler(
            createFetchUserAll(),
            {id_customer: customerId, access_level: '<>' + RIGHTS.LOCKED},
            (result) => {
                this.setState({
                    users: result,
                })
            }
        )
    };

    onFinishAddingUser = (result) => {
        const {onChange} = this.props;
        this.fetchUsers();
        if(onChange != null)
            onChange(result);
    }

    render() {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, customerId, addOption, onChange, onChangeWithData, ...props} = this.props;
        const firstCustomerId = customerId != null && Array.isArray(customerId) ? 
            (customerId.length > 0 ? customerId[0] : null)
            :
            customerId;
            
        const {users} = this.state;
        return <Select
            {...props}
            onChange={this.onChanged}
            options={users.map(user => {
                const userChecked = user != null ? user : {};
                return {
                    value: userChecked.id,
                    label: formatUserInfoName(userChecked),
                }
            })}
            after={addOption == true ? 
                <FormModal
                    {...props}
                    disabled={firstCustomerId == null}
                    openNode={
                        <Button className="mt-2" disabled={firstCustomerId == null} >
                            <Icon type="user-add" className="pr-2"/>
                            <Trans>Pridať kontakt</Trans>
                        </Button>
                    }
                    values={{firstCustomerId}}
                    title={<Trans>Pridať kontakt</Trans>}
                    Form={AddUserForm}
                    onFinishSuccessful={this.onFinishAddingUser}
                    Failed={generalFailedPC(t`Nepodarilo sa pridať kontakt`)}
                /> :
                null
            }
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(UserSelectByCustomer);