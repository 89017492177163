import React from 'react'
import Spinner from '../graphics/Spinner';
import lockHOC from './lockHOC';

/**
 * @fero
 */

class Loading extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loadingAnimation: false,
        };
    }

    componentDidMount() {
        this.setSpinner();
    }

    componentDidUpdate() {
        this.setSpinner();
    }

    setSpinner = () => {
        const {isLocked} = this.props;
        if(isLocked)
        {
            // avoid instant spinner for small load times
            setTimeout(() => this.setState({loadingAnimation: true}), 250);
        }
        else
        {
            // hide spinner instantly after done
            this.setState({loadingAnimation: false});
        }
    }

    render() {
        const {children} = this.props;
        const {loadingAnimation} = this.state;
        
        return <div className="full-size-height full-size-width position-relative">
            <div className={"full-size-height full-size-width position-absolute-top-0 " + (loadingAnimation ? " shop-table-loading" : "")}>
                {children}
            </div>
            {loadingAnimation ? <div className="full-size-height full-size-width position-absolute-top-0">
                <Spinner/>
            </div> : null}
        </div>;
    }
}

export default lockHOC(Loading);