import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Customer} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class CustomersSelect extends React.Component {
    static propTypes = {
        [GLOBAL_DATA.CUSTOMERS]: PropTypes.arrayOf(Customer.isRequired).isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
        className: PropTypes.string,
        focus: PropTypes.bool,
        allowClear: PropTypes.bool,
        onChange: PropTypes.func
    };

    static defaultProps = {
        className: '',
    };

    shouldComponentUpdate(nextProps) {
        const {[GLOBAL_DATA.CUSTOMERS]: customers, value, className, focus, allowClear, onChange} = this.props;
        const {
            [GLOBAL_DATA.CUSTOMERS]: nextCustomers, value: nextValue, className: nextClassName, allowClear: nextAllowClear,
            focus: nextFocus, onChange: nextOnChange
        } = nextProps;
        return !(
            customers === nextCustomers && value === nextValue && className === nextClassName &&
            allowClear === nextAllowClear && focus === nextFocus && onChange === nextOnChange
        );
    }

    compareCustomer(option, search) {
        const customerName = option.label != null ? option.label : '';
        const haystack = customerName.normalize("NFD").replace(/[\u0300-\u036f\s\-\(\)]+/ig, '').toLowerCase();
        const needle = search.normalize("NFD").replace(/[\u0300-\u036f\s\-\(\)]+/ig, '').toLowerCase();
        return haystack.includes(needle);
    }

    onChanged = (newCustomerId) => {
        const {[GLOBAL_DATA.CUSTOMERS]: customers, onChange, onChangeWithData} = this.props;
        onChange(newCustomerId);

        if(onChangeWithData != null)
        {
            const newCustomer = newCustomerId != null && customers != null ? customers.find(c => c.id == newCustomerId) : null;
            onChangeWithData(newCustomer);
        }
    }

    render() {
        const { [GLOBAL_DATA.CUSTOMERS]: customers, value, className, onChange, onChangeWithData, ...props } = this.props;
        const valueChecked = value != null ? 
            (Array.isArray(value) ? value.map(v => Number(v)) : Number(value))
            : null;
        
        return <Select
            className={className}
            {...props}
            onChange={this.onChanged}
            options={customers.map(customer => {
                return {
                    label: customer.name,
                    value: customer.id,
                }
            })}
            value={valueChecked}
            compareFunc={this.compareCustomer}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.CUSTOMERS])(CustomersSelect);