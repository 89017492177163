import React from 'react';
import PropTypes from 'prop-types';
import CountriesSelect from '../project/CountriesSelect';
import {merge, removeNullAttributes} from '../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Checkbox, Divider, Form, Radio} from 'antd';
import {createUserRegister, createFetchOrsrSearch} from '../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, notFalseRule} from '../../constants/form';
import {ROUTES} from '../../constants/navigation';
import {getFullPath} from '../../lib/url';
import InputText from '../general/InputText';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
const FormItem = Form.Item;

/**
 * @fero
 */

const mandatory = {
    rules: [{
        required: true, message: 'Povinné',
    }],
};

const mandatoryOnSubmit = merge(mandatory, {validateTrigger: 'onSubmit'});

const notFalse = {
    rules: [notFalseRule]
};

class RegisterForm extends React.Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        submitLabel: PropTypes.node,
        cancelLabel: PropTypes.node,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            useDelivery: false,
            orsrRecord: undefined,
            userRecord: undefined,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    onUseDeliveryChange = (event) => {
        const useDelivery = event.target.value;
        this.setState({
            useDelivery: useDelivery,
        })
    };

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        const {orsrRecord} = this.state;
        e.preventDefault();
        this.props.form.validateFields((err, formValues) => {
            const orsr = orsrRecord != null ? orsrRecord : {};
            let orsrValues = {
                set_invoice_address: true,
                dic: orsr.dic,
                organization: orsr.name,
                ic_dph: orsr.ic_dph,
                invoice_address: orsr.address
            };
            removeNullAttributes(orsrValues);
            const formValuesChecked = merge(formValues, orsrValues);
            if (formValues.password == formValues.repeat_password) {
                if (!err) {
                    this.setState({loading: true});
                    createUserRegister()(formValuesChecked).then((response) => {
                        this.setState({
                            loading: false,
                        });
                        onSubmitFinish(response);
                    });
                }
            } else {
                this.props.form.setFields({
                    repeat_password: {
                        value: formValues.repeat_password,
                        errors: [new Error('Heslo sa nezhoduje.')],
                    },
                });
            }
        });
    };

    fetchOrsr = (ico) => {
        const {userRecord} = this.state;
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const {setFieldsValue, getFieldsValue} = this.props.form;
        if(ico == null || ico == '')
        {
            if(userRecord != null) {
                const userInvoiceAddress = userRecord.invoice_address != null ? userRecord.invoice_address : {};
                setFieldsValue({
                    dic: userRecord.dic,
                    organization: userRecord.name,
                    ic_dph: userRecord.ic_dph,
                    'invoice_address[organization]': userInvoiceAddress.organization,
                    'invoice_address[street]': userInvoiceAddress.street,
                    'invoice_address[city]': userInvoiceAddress.city,
                    'invoice_address[zip_code]': userInvoiceAddress.zip_code,
                    'invoice_address[id_country]': userInvoiceAddress.id_country,
                });
            }

            this.setState({orsrRecord: undefined, userRecord: undefined});
            return;
        }

        this.setState({loading: true});
        fetchHandler(
            createFetchOrsrSearch(),
            {ico: ico},
            (result) => {
                const orsr = result != null && result.length > 0 ? result[0] : undefined;
                const orsrAddress = orsr != null && orsr.address != null ? orsr.address : {};
                const prevFields = (orsr != null && userRecord == null) ? getFieldsValue([
                    'dic', 
                    'organization', 
                    'ic_dph', 
                    'invoice_address[organization]',
                    'invoice_address[street]',
                    'invoice_address[city]',
                    'invoice_address[zip_code]',
                    'invoice_address[id_country]'
                ]) : userRecord;

                if(orsr != null){
                    setFieldsValue({
                        dic: orsr.dic,
                        organization: orsr.name,
                        ic_dph: orsr.ic_dph,
                        'invoice_address[street]': orsrAddress.street,
                        'invoice_address[city]': orsrAddress.city,
                        'invoice_address[zip_code]': orsrAddress.zip_code,
                        'invoice_address[id_country]': orsrAddress.id_country,
                    });
                }

                this.setState({
                    orsrRecord: orsr,
                    userRecord: prevFields,
                    loading: false,
                });
            }
        );
    };

    render() {
        const {loading, useDelivery, orsrRecord = {}} = this.state;
        const {onClose, submitLabel, cancelLabel} = this.props;
        const {getFieldDecorator} = this.props.form;
        
        return (
            <Form onSubmit={this.handleSubmit} className="">
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Titul</Trans>}
                >
                    {getFieldDecorator('title', {})(
                        <InputText ref={node => this.first = node}/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Meno</Trans>}
                >
                    {getFieldDecorator('name', mandatory)(
                        <InputText/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Priezvisko</Trans>}
                >
                    {getFieldDecorator('surname', mandatory)(
                        <InputText/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Email</Trans>}
                >
                    {getFieldDecorator('e_mail', {
                        rules: [
                            {required: true, message: 'Povinné'},
                            {type: 'email', message: 'Nesprávny formát.'}
                        ],
                        validateTrigger: 'onSubmit',
                    })(
                        <InputText/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Telefón</Trans>}
                >
                    {getFieldDecorator('phone', mandatory)(
                        <InputText/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Organizácia</Trans>}
                >
                    {getFieldDecorator('organization', {})(
                        <InputText
                            disabled={orsrRecord.name != null}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>IČO</Trans>}
                >
                    {getFieldDecorator('ico', {})(
                        <InputText
                            onChange={this.fetchOrsr}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>DIČ</Trans>}
                >
                    {getFieldDecorator('dic', {})(
                        <InputText
                            disabled={orsrRecord.dic != null}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>IČ DPH</Trans>}
                >
                    {getFieldDecorator('ic_dph', {})(
                        <InputText
                            disabled={orsrRecord.ic_dph != null}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Heslo</Trans>}>
                    {getFieldDecorator('password', mandatoryOnSubmit)(
                        <InputText type="password"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Kontrola hesla</Trans>}>
                    {getFieldDecorator('repeat_password', mandatoryOnSubmit)(
                        <InputText type="password"/>
                    )}
                </FormItem>
                <Divider orientation="left"><Trans>Fakturačná adresa</Trans></Divider>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Popis</Trans>}>
                    {getFieldDecorator('invoice_address[organization]', {})(
                        <InputText
                            disabled={orsrRecord.address != null}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Ulica</Trans>}>
                    {getFieldDecorator('invoice_address[street]', mandatory)(
                        <InputText
                            disabled={orsrRecord.address != null}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Mesto</Trans>}>
                    {getFieldDecorator('invoice_address[city]', mandatory)(
                        <InputText
                            disabled={orsrRecord.address != null}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>PSČ</Trans>}>
                    {getFieldDecorator('invoice_address[zip_code]', mandatory)(
                        <InputText
                            disabled={orsrRecord.address != null}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Krajina</Trans>}>
                    {getFieldDecorator('invoice_address[id_country]', {})(
                        <CountriesSelect
                            disabled={orsrRecord.address != null}
                        />
                    )}
                </FormItem>
                <Divider orientation="left"><Trans>Adresa pre doručenie tovaru</Trans></Divider>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Zadať</Trans>}
                >
                    {getFieldDecorator('set_delivery_address', {
                        initialValue: false,
                        onChange: this.onUseDeliveryChange
                    })(
                        <Radio.Group>
                            <Radio.Button value={false}><Trans>Rovnaká ako fakturačná adresa</Trans></Radio.Button>
                            <Radio.Button value={true}><Trans>Zadať inú adresu</Trans></Radio.Button>
                        </Radio.Group>
                    )}
                </FormItem>
                { useDelivery ? 
                    <React.Fragment>
                        <FormItem
                            {...formItemLayout}
                            label={<Trans>Popis</Trans>}>
                            {getFieldDecorator('delivery_address[organization]', {})(
                                <InputText/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={<Trans>Ulica</Trans>}>
                            {getFieldDecorator('delivery_address[street]', mandatory)(
                                <InputText/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={<Trans>Mesto</Trans>}>
                            {getFieldDecorator('delivery_address[city]', mandatory)(
                                <InputText/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={<Trans>PSČ</Trans>}>
                            {getFieldDecorator('delivery_address[zip_code]', mandatory)(
                                <InputText/>
                            )}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={<Trans>Krajina</Trans>}>
                            {getFieldDecorator('delivery_address[id_country]', mandatory)(
                                <CountriesSelect/>
                            )}
                        </FormItem>
                    </React.Fragment>
                    : null
                }
                <FormItem {...tailFormItemLayout}>
                    {getFieldDecorator('commercial_terms', notFalse)(
                        <span>
                        <Checkbox className="mr-2"/>
                        <Trans>Súhlasím s </Trans>
                        <a href={getFullPath(ROUTES.COMMERCIAL_TERMS)} target="_blank">
                            {' '}<Trans>obchodnými podmienkami.</Trans>
                        </a>
                    </span>
                    )}
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    {getFieldDecorator('personal_data_protection', notFalse)(
                        <span>
                        <Checkbox className="mr-2"/>
                        <Trans>Súhlasím so </Trans>
                        <a href={getFullPath(ROUTES.PERSONAL_DATA_PROTECTION)} target="_blank">
                            {' '}<Trans>zásadami ochrany údajov.</Trans>
                        </a>
                    </span>
                    )}
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    {getFieldDecorator('claim_rules', notFalse)(
                        <span>
                        <Checkbox className="mr-2"/>
                        <Trans>Súhlasím s </Trans>
                        <a href={getFullPath(ROUTES.CLAIM_RULES)} target="_blank">
                            {' '}<Trans>reklamačným poriadkom.</Trans>
                        </a>
                    </span>
                    )}
                </FormItem>
                <FormItem
                    {...tailFormItemLayout}
                >
                    <Button onClick={onClose}>
                        {cancelLabel != null ? cancelLabel : <Trans>Zrušiť</Trans>}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {submitLabel != null ? submitLabel : <Trans>Registrovať</Trans>}
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(RegisterForm));