import React from 'react';
import PropTypes from 'prop-types';
import InfoAttribute from './InfoAttribute';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class InfoAttributeGroup extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.node,
        attributes: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.node,
            value: PropTypes.node,
            isRequired: PropTypes.bool,
            hasError: PropTypes.bool,
            nullLabel: PropTypes.node,
            isHidden: PropTypes.bool,
        }).isRequired).isRequired,
    };

    static defaultProps = {
        className: "info-attribute-group",
    };

    render() {
        const {attributes, className, title} = this.props;
        const attributesFiltered = attributes.filter(attr => { 
            return (attr.isHidden != true && (attr.title != null || attr.value != null)); 
        });
        return <div className={className}>
            {title != null ? <h4 className="text-center">{title}</h4> : null}
            {attributesFiltered.map((attribute, index) => {
                return <InfoAttribute
                    key={index}
                    attributeTitle={attribute.title}
                    attributeValue={attribute.value}
                    nullLabel={attribute.nullLabel}
                    isRequired={attribute.isRequired}
                    hasError={attribute.hasError}
                />
            })}
        </div>;
    }

}

export default InfoAttributeGroup;