/**
 * @fero
 */

export const ERROR_CODES = {
    OK: 0, 						// Everything is fine
	ACCESS_DENIED : 1,			// Not enough priviledges. Login may be required.
	BAD_METHOD : 2,				// Invalid method called
	INVALID_ARGUMENT : 3,		// Invalid argument provided
	INVALID_FORMAT : 31,		// Invalid format of the input argument
	MISSING_FIELD : 32,			// Missing input field
	INVALID_VALUE : 33,			// Given value is not allowed.
	USER_LOCKED : 4,			// User account locked by administrator
	USER_PENDING : 5, 			// User e-mail not verified
	USER_INVALID_PASSWORD : 6,	// Provided password is invalid
	USER_TOO_MANY_ATTEMPTS : 7,	// Too many account attempts
	USER_UNKNOWN_EMAIL : 8,		// Unknown account
	USER_INVALID_TOKEN : 9,		// Invalid activation token
	SEND_MAIL_FAILED : 10,		// Unable to send e-mail
	CATEGORY_FULL : 11,			// Maximal count of sub-categories per category reached
	RESULT_OVERFLOW : 12,		// Too many results, narrow search condition
	USER_INVALID_PIN: 13,		// Invalid PIN provided
};