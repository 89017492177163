import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
import { numberOrNull } from '../../lib/number';

/**
 * Component that enables input number. Number may contain one '.' or ',' as a decimal separator(cannot be first).
 * This component may return empty string or a '-'. This cannot be avoided on onChange event so onSuvbmit checking is
 * required.
 *
 * @fero
 */

class InputNumber extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        wrapperClassName: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        after: PropTypes.node,
        step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        changeImmediately: PropTypes.bool,
    };

    static defaultProps = {
        wrapperClassName: '',
        className: '',
        changeImmediately: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            itsValue: props.value,
            itsLastValue: props.value,
        };
    }

    componentDidMount() {
        const {value} = this.props;
        this.setState({
            itsValue: value,
            itsLastValue: value,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {value} = this.props;
        const {itsLastValue} = this.state;
        const {itsLastValue: itsPrevValue} = prevState;
        if( value !== itsLastValue && itsPrevValue == itsLastValue )
        {
            this.setState({
                itsValue: value,
                itsLastValue: value,
            });
        }
    }
    
    onChanged = (ev) => {
        const {changeImmediately, onChange} = this.props;
        const value = ev != null && ev.target != null ? ev.target.value : null;
        const valueChecked = value != null ?
            value.replace(/[^\d\.\,\-]+/g, '').replace(',', '.') :
            null;
        this.setState({itsValue: valueChecked});
        const valueNum = numberOrNull(valueChecked);
        if((changeImmediately == true || valueChecked == '') && valueChecked != '-' && onChange != null)
            onChange(valueNum);
    };

    onBlur = () => {
        const {itsValue, itsLastValue} = this.state;
        const {onChange, step} = this.props;
        const stepChecked = numberOrNull(step);
        let valueChecked = numberOrNull(itsValue);
        if(valueChecked != null && stepChecked != null && stepChecked > 0)
            valueChecked = stepChecked * Math.ceil((valueChecked - Math.sign(valueChecked) * 1e-8) / stepChecked);

        this.setState({itsValue: valueChecked, itsLastValue: valueChecked});
        if (valueChecked != itsLastValue && onChange != null)
            onChange(valueChecked);
    };

    focus() {
        if (this.input != null) {
            this.input.select();
        }
    }

    blur() {
        if(this.input != null) {
            this.input.blur();
        }
    }

    render() {
        const {itsValue} = this.state;
        const {value, step, onChange, after, className, wrapperClassName, changeImmediately, ...props} = this.props;
        return <div className={"d-flex align-items-center " + wrapperClassName}>
            <div className="full-size-width">
                <Input
                    {...props}
                    className={className}
                    onChange={this.onChanged}
                    onBlur={this.onBlur}
                    onFocus={(ev) => {
                        ev.target.select()
                    }}
                    onPressEnter={this.onBlur}
                    value={itsValue}
                    ref={node => this.input = node}
                />
            </div>
            {after}
        </div>;
    }

}

export default InputNumber;