import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {stopPropagation} from '../../lib/interaction';
import {Button, Modal} from 'antd';
import {Trans, t} from '@lingui/macro';
import locationHOC from '../locationProvider/locationHOC';

/**
 * Simple modal for displaying data. Enables to define opening node, node is wrapped in div that caches click event and
 * opens modal. For footer in modal there is only button to close it. It is possible to perform callbacks on open and
 * close of modal. Whole modal is wrapped in div that prevents propagation of events, className may be set for this
 * wrapping div if needed. modalClassName attribute specifies
 *
 * @fero
 */

class DisplayModal extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node,
        openNode: PropTypes.node.isRequired,
        closeLabel: PropTypes.node,
        onClose: PropTypes.func,
        closable: PropTypes.bool,
        className: PropTypes.string,
        modalClassName: PropTypes.string,
    };

    static defaultProps = {
        className: "",
        modalClassName: "",
        closeLabel: <Trans>Zavrieť</Trans>,
        closable: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            x0: 0,
            y0: 0,
            top0: 0,
            left0: 0,
            top: undefined,
            left: undefined,
            dragging: false,
        }
    };

    componentDidUpdate(prevProps) {
        const {location} = this.props;
        const {location: prevLocation} = prevProps;

        if(prevLocation.pathname != location.pathname || prevLocation.search != location.search)
            this.setState({visible: false});
    }

    handleOpen = () => {
        const {onOpen} = this.props;
        if(onOpen != null)
            onOpen();

        this.setState({visible: true});
    };

    handleClose = () => {
        const {onClose} = this.props;
        if(onClose != null)
            onClose();
        
        this.setState({visible: false});
    };

    getModalNode = () => {
        if(this.modal == null)
            return null;

        const root = ReactDOM.findDOMNode(this.modal);

        if(root.childElementCount < 2)
            return null;

        const wrapper = root.childNodes[1];
        if(wrapper.childElementCount < 1)
            return null;

        return wrapper.childNodes[0];
    }

    handleMouseDown = (e) => {
        const node = this.getModalNode();
        if(node != null)
        { 
            e.preventDefault();
            
            document.onmousemove = this.handleMouseMove;
            document.onmouseup = this.handleMouseUp;

            const rect = node.getBoundingClientRect();
            const left = parseFloat(node.style.left);

            this.setState({
                dragging: true, 
                x0: e.clientX, 
                y0: e.clientY, 
                top0: rect.top,
                left0: Number.isFinite(left) ? left : 0
            });
        }
    }

    handleMouseMove = (e) => {
        const {dragging, left0, top0, x0, y0} = this.state;
        
        if(dragging)
        {
            e.preventDefault();
            
            const node = this.getModalNode();
            if(node != null)
            {
                node.style.top = `${top0 + e.clientY - y0}px`;
                node.style.left = `${left0 + e.clientX - x0}px`;
            }
        }
    }

    handleMouseUp = (e) => {
        e.preventDefault();
        document.onmousemove = null;
        this.setState({dragging: false});
    }

    render() {
        const {title, openNode, closeLabel, className, modalClassName, closable, children} = this.props;
        const {visible} = this.state;
        return <div className={className} onClick={stopPropagation} onKeyDown={stopPropagation}>
            <div onClick={this.handleOpen}>
                {openNode}
            </div>
            <Modal
                ref={node => this.modal = node}
                className={modalClassName}
                visible={visible}
                title={
                    <div
                        onMouseDown={this.handleMouseDown}
                        style={{cursor: 'move', width: '100%'}}
                    >
                        {title}
                    </div>
                }
                onCancel={this.handleClose}
                destroyOnClose={true}
                keyboard={true}
                closable={closable}
                maskClosable={closable == false}
                footer={closeLabel != null ? <Button onClick={this.handleClose}>
                    {closeLabel}
                </Button> : null}
            >
                {children}
            </Modal>
        </div>
    }

}

export default locationHOC(DisplayModal);