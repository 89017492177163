export const RIGHTS = {
    ADMIN: 10,
    MANAGER: 9,
    SUPERVISOR: 8,
    DEALER: 7,
    WORKER: 6,
    MARKETING: 5,
    DISTRIBUTOR: 4,
    WHOLESALE_CUSTOMER: 3,
    CUSTOMER: 2,
    PENDING: 1,
    UNREGISTERED: 0,
    LOCKED: -1,
};