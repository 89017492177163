import React from 'react';
import PropTypes from 'prop-types';
import InputText from './InputText';
import { formatTimeAPI, formatTimeHTML } from '../../lib/date';

/**
 * @dusan
 */

class TimePicker extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string,
    };

    handleChange = (newVal) => {
        const {onChange} = this.props;
        if(onChange != null)
            onChange(formatTimeAPI(newVal));
    }

    render() {
        const {value, onChange, min, max, ...props} = this.props;
        return <InputText
            type="time"
            value={formatTimeHTML(value)}
            min={formatTimeHTML(min)}
            max={formatTimeHTML(max)}
            onChange={this.handleChange}
            {...props}
        />;
    }

}

export default TimePicker;