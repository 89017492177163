import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

/**
 * @fero
 */

class Exception extends React.PureComponent {
    static propTypes = {
        response: PropTypes.object.isRequired,
    };

    render() {
        const { response } = this.props;
        return <div>
            <div>{t`Pri komunikácií so serverom sa vyskytla chyba`}</div>
            {JSON.stringify(response.result)}
        </div>;
    }

}

export default Exception;