import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from './withSessionHOC';
import {SESSION_ATTRIBUTES} from './SessionProvider'
import {RIGHTS} from '../../constants/Rights';

/**
 * @fero
 */

class RightsWrapper extends React.Component {
    //maybe this should address cookie by itself
    static propTypes = {
        from: PropTypes.number,
        to: PropTypes.number,
        deny: PropTypes.arrayOf(PropTypes.number),
    };

    render() {
        const {from, to, deny, children} = this.props;
        const rights = this.props[SESSION_ATTRIBUTES.RIGHTS];
        const fromChecked = from != null ? from : RIGHTS.LOCKED;
        const toChecked = to != null ? to : RIGHTS.ADMIN;
        const denyChecked = deny != null ? deny : [];
        return (rights <= toChecked && rights >= fromChecked && !denyChecked.includes(rights)) ? (children != null ? children : null) : null;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(RightsWrapper);