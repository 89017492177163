import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';

/**
 * @fero
 */

class RegisterResponse extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.RELOAD_RIGHTS]: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const reloadRights = this.props[SESSION_ATTRIBUTES.RELOAD_RIGHTS];
        reloadRights();
    }

    render() {
        return <div>
            <h3>{t`Úspešne prijaté.`}</h3>
            <span>{t`Bol Vám zaslaný email obsahujúci odkaz, pomocou ktorého možete pokračovať v registrácií.`}</span>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RELOAD_RIGHTS])(RegisterResponse);