// @flow
import React from 'react';
import {DataContext} from './DataProvider';

/**
 * @fero
 */

const lockHOC = (Component) => {
    return class extends React.Component {
        render() {
            return <DataContext.Consumer>
                {context => {
                    const strippedContext = {
                        isLocked: context.loading > 0 || context.dataLock,
                        setDataLoadLock: context.setDataLoadLock,
                    };
                    return <Component  {...strippedContext} {...this.props}/>
                }}
            </DataContext.Consumer>;
        }
    }
};

export default lockHOC;