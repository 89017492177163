export const ORDER_STATE = {
    DRAFT: 'R',
    PROCESSING: 'A',
    NOT_COVERED: 'N',
    WAITING_FOR_SUPPLY: 'S',
    FINISHED: 'U'
};

export const ORDER_LINE_STATE = {
    DRAFT: 0,
    PROCESSING: 1,
    WAITING_FOR_SUPPLY: 2,
    WAITING_FOR_DISPATCH: 3,
    PARTIALLY_DISPATCHED: 4,
    FINISHED: 5,
    NOT_COVERED: 6,
    PACKING: 7,
};